import React from 'react';
import { RiDeleteBinLine,RiUserFill } from 'react-icons/ri';
import { AiOutlineMail,AiOutlineRight,AiOutlineLock } from 'react-icons/ai';
import { VscBell } from 'react-icons/vsc';
import { BsUnlock } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

const ProfilePage = () => { 
  const userData = useSelector((state) => state.users.userData.data);

  return (
    <div className="w-full flex flex-col justify-start items-start p-7 pl-11">
      <div className="w-[83%]"> 
        <div className="text-[24px] medium text-[#1A202C] border-b pb-4 tracking-wide">
          Your profile
        </div>
    
        <div>
          <div className="flex my-6 justify-between">
            <div className="flex gap-4 ">
              <div
                style={{
                  backgroundColor: '#D2FAF1',
                }} 
                className="h-20 w-20 rounded-[17px]  flex items-end justify-center cursor-pointer "
              >
                {' '}
                <RiUserFill className="!text-[#2EB597] !text-[64px] " />
                {' '}
              </div>
              <div>
                <div className="text-[24px] medium text-[#1A202C] ">
                  {userData?.data?.firstName}
                  {' '}
                  {userData?.data?.lastName}
                </div>
                <div className="text-[14px] text-[#555C67]">{userData?.data?.accessLevel}</div>
                <div className="text-[14px] flex items-center gap-1 text-[#555C67]">
                  <AiOutlineMail />
                  {userData?.data?.email}
                </div>
              </div>
            </div>
            <div>
              {/* <button
              onClick={() => setIsProfileOpen(true)}
              type="button"
              className="flex justify-center bg-white text-[#12151A] 
              shadow-md shadow-gray-200 focus:ring-4 gap-1  border border-gray-300
              medium text-[13px] rounded-[10px] focus:!ring-blue-200 
              focus:!border-blue-500  focus:border-[1px] py-1 px-3"
            >
              Edit profile
            </button> */}

            </div>
          </div>
          <div className="flex justify-between items-center cursor-pointer mt-12 hover:bg-[#F7FAFC] p-3 rounded-2xl">
            <div className="flex items-center gap-4">
              <VscBell className="text-[20px] text-[#555C67]" />
              <div className="flex flex-col">
                <span className="text-[#292E36] medium text-[14px]">Notification</span>
                <span className="text-[14px] text-[#555C67]">Determine how you get notifications and what you&apos;re notified about.</span>
              </div>
            </div>
            <AiOutlineRight className="text-[18px] text-[#555C67]" />
          </div>
          <div className="flex justify-between items-center mt-4  p-3 rounded-2xl">
            <div className="flex items-center gap-4">
              <BsUnlock className="text-[20px] text-[#555C67]" />
              <div className="flex flex-col">
                <span className="text-[#292E36] medium text-[14px]">Password</span>
                <span className="text-[14px] text-[#555C67]">Update your Elyzian password.</span>
              </div>
            </div>
            {/* <button
              onClick={() => setIsOpen(true)}
              type="button"
              className="flex justify-center bg-white text-[#12151A] 
            shadow-md shadow-gray-200 focus:ring-4 gap-1  border border-gray-300
            medium text-[13px] rounded-[10px] focus:!ring-blue-200 
            focus:!border-blue-500  focus:border-[1px] py-1 px-3"
            >
              Update password
            </button> */}
          </div>        
        </div>
      </div>
 
    </div>
  );
};

export default ProfilePage;
