import axios from './axiosConfig';

import { baseURL, companyId, config } from '../slice/HEADERS';

const getAllReviews = () => axios.get(`${baseURL}/reviews/get-all-reviews/${companyId}`,config);

const getDBReviews = (types,search,page,range,minRating,maxRating) => axios.get(`${baseURL}/reviews/get-reviews/${types}/${companyId}?search=${search}&page=${page}&pageSize=2&dateRange=${range}&minRating=${minRating}&maxRating=${maxRating}`,config);

const replyById = (payload) => axios.put(`${baseURL}/reviews/update-review/${companyId}`,payload,config);

const reviewAnalytics = () => axios.get(`${baseURL}/reviews/analytics/${companyId}`,config);

const reviewApi = {
  getAllReviews,
  getDBReviews,
  replyById,
  reviewAnalytics,
};
    
export default reviewApi;
