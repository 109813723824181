import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUsers } from '../../../slice/userSlice';
import { baseURL, companyId } from '../../../slice/HEADERS';
import Button from '../../ReusableComponents/Button';
import SimpleModal from '../../ReusableComponents/SimpleModal';
import InviteUsers from './InviteUsers';
import AllUsers from './AllUsers';

const Users = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector((state) => state.users.users);

  const [inviteUsersModel, setInviteUsersModel] = useState(false);
  console.log(data, isLoading, isError);

  // Check if data is null or if you need to fetch it
  useEffect(() => {
    if (!data) {
      // Dispatch the action to fetch data if it's null
      // dispatch(fetchAllUsers());
    }
  }, [data, dispatch]);

  return (
    <div className="w-full p-7">
      <div className="flex justify-between pb-4 border-b">
        <span className="text-2xl medium text-[#1A202c]">Users</span>
        <div className="flex gap-4">
          <Button 
            blue
            buttonText="Invite users"
            onClick={() => setInviteUsersModel(true)}
          />
        </div>
      </div>

      <AllUsers users={data} />

      <SimpleModal open={inviteUsersModel} setOpen={setInviteUsersModel}>
        <InviteUsers setInviteUsersModel={setInviteUsersModel} />
      </SimpleModal>
    </div>
  ); 
};

export default Users;
