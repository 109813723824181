import React, { useState } from 'react';
import Customizebubble from './Customizebubble';
import PreviewBackground from './PreviewBackground';
import Bubble from './Bubble';

function WebchatBubble({
  greeting, setGreeting, color,setColor,imageUrl,setImageUrl, setActiveTab, setIconType,icons,iconType,

}) {
  return (
    <div className="w-full h-full flex">
      <div className="w-[45%] h-full bg-[#fafbfd]">
        <Customizebubble
          imageUrl={imageUrl}
          setIconType={setIconType}
          icons={icons}
          setActiveTab={setActiveTab}
          setImageUrl={setImageUrl}
          greeting={greeting}
          setGreeting={setGreeting}
          color={color}
          setColor={setColor}
        />
      </div>
      <div className="w-[55%] h-full relative">
        <PreviewBackground />
        <Bubble greeting={greeting} imageUrl={imageUrl} color={color} iconType={iconType} icons={icons} />
      </div>
    </div>
  );
}

export default WebchatBubble;
