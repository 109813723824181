/* eslint-disable max-len */
import React, { useState } from 'react';
import { RiDeleteBinLine,RiUserFill } from 'react-icons/ri';
import { CiSettings,CiLogout } from 'react-icons/ci';
import { BiNotepad } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getColorForAlphabet } from '../Common/Colors';
import MouseOverPopover from '../ReusableComponents/MouseOverPopover';
import Profile from './NavBarComponents/Profile';

function NavbarRight() {
  const userData = useSelector((state) => state.users.userData.data);

  // console.log('userData',userData);
  const [showProfile, setshowProfile] = useState(false);
  const regex = /^[^a-zA-Z]/;

  const handleLogout = () => {
    localStorage.setItem('authToken', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('compId', '');
    window.location.reload();
  };

  const profileOptions = [
    {
      id: 1,
      icon: <CiSettings />,
      text: 'Settings',
      onClick: '/account/settings/your-profile',
    },
    {
      id: 2,
      icon: <BiNotepad />,
      text: 'Terms & condition',
      onClick: '/',
    },
    {
      id: 3,
      icon: <CiLogout />,
      text: 'Sign out',
      onClick: handleLogout,
    },
  ];
  return (
    <div className="pr-6 flex gap-3 relative">
      {/* Add New */}
      <MouseOverPopover 
        mainContent={(
          <button type="button" className="bg-[#466EFF] buttonShadow hover:bg-[#1E47F6] transition-all delay-150 ease-out text-white w-8 h-8 rounded-lg flex justify-center items-center  ">
            <svg fill="currentColor" height="26" viewBox="0 0 24 24" width="26" xmlns="http://www.w3.org/2000/svg" data-testid="icon-svg">
              <path clipRule="evenodd" d="M12 4.00488C12.3452 4.00488 12.625 4.2847 12.625 4.62988V19.3755C12.625 19.7207 12.3452 20.0005 12 20.0005C11.6548 20.0005 11.375 19.7207 11.375 19.3755V4.62988C11.375 4.2847 11.6548 4.00488 12 4.00488Z" fillRule="evenodd" />
              <path clipRule="evenodd" d="M4.0022 12.0029C4.0022 11.6578 4.28202 11.3779 4.6272 11.3779H19.3724L19.3728 12.6279H4.6272C4.28202 12.6279 4.0022 12.3481 4.0022 12.0029ZM19.9978 12.0029C19.9978 12.3481 19.7179 12.6279 19.3728 12.6279L19.3724 11.3779C19.7176 11.3779 19.9978 11.6578 19.9978 12.0029Z" fillRule="evenodd" />
            </svg>
          </button>
        )}
        popoverContent="New"
      />

      {/* Integration */}
      <MouseOverPopover 
        mainContent={(
          <button type="button" className="bg-transparent buttonShadow hover:bg-[#ffffff14] transition-all delay-150 ease-out text-white w-8 h-8 rounded-lg flex justify-center items-center  ">
            <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-testid="icon-svg" style={{ fill: 'none' }}>
              <g clipPath="url(#clip0_7841_54075)">
                <path d="M9.04014 12.9342C8.10105 11.9951 7.53541 10.7595 7.46765 9.49917C7.39989 8.23886 7.83556 7.05708 8.67882 6.21382L10.2686 4.62405C10.4794 4.41323 10.7748 4.30432 11.0899 4.32126C11.405 4.3382 11.7139 4.4796 11.9487 4.71439L17.26 10.0256C17.4947 10.2604 17.6361 10.5693 17.6531 10.8844C17.67 11.1995 17.5611 11.4949 17.3503 11.7057L15.7605 13.2955C14.9172 14.1388 13.7355 14.5744 12.4752 14.5067C11.2148 14.4389 9.97923 13.8733 9.04014 12.9342Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M7.52295 10.9109L6.66411 11.7698C6.42236 12.0115 6.28374 12.3367 6.27879 12.6737C6.27384 13.0106 6.40297 13.3318 6.63775 13.5666L8.40817 15.337C8.64293 15.5718 8.96412 15.7009 9.3011 15.696C9.63805 15.691 9.9632 15.5524 10.205 15.3106L11.0638 14.4518" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M13.087 5.34681L15.3633 3.07056" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M16.6276 8.88764L18.9038 6.61139" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M7.47238 14.5022L5.95487 16.0197C5.47191 16.5027 5.19526 17.1524 5.18577 17.8259C5.17628 18.4995 5.43474 19.1416 5.90428 19.6112C6.37383 20.0807 7.016 20.3392 7.68952 20.3297C8.36304 20.3202 9.01275 20.0435 9.49571 19.5606C9.97867 19.0776 10.6284 18.801 11.3019 18.7915C11.9754 18.782 12.6176 19.0404 13.0871 19.51C13.5567 19.9795 13.8151 20.6217 13.8057 21.2952" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
              </g>
              <defs><clipPath id="clip0_7841_54075"><rect fill="white" height="24" width="24" /></clipPath></defs>
            </svg>
          </button>
        )}
        popoverContent="Apps Home"
      />

      {/* Tips */}
      <MouseOverPopover 
        mainContent={(
          <button type="button" className="bg-transparent buttonShadow hover:bg-[#ffffff14] transition-all delay-150 ease-out text-white w-8 h-8 rounded-lg flex justify-center items-center  ">
            <svg fill="currentColor" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-testid="icon-svg">
              <path d="M12.0008 6.37583C13.727 6.37583 15.126 7.77587 15.125 9.50167C15.125 10.8254 14.29 12.0058 13.0416 12.4476C12.823 12.5249 12.668 12.7153 12.6327 12.9395L12.625 13.0375V13.8758C12.625 14.221 12.3452 14.5008 12 14.5008C11.6862 14.5008 11.4264 14.2696 11.3818 13.9682L11.375 13.8758V13.0375C11.375 12.2954 11.811 11.6288 12.4783 11.3279L12.6248 11.2691C13.3739 11.004 13.875 10.2956 13.875 9.50129C13.8756 8.46574 13.0363 7.62583 12.0005 7.62583C10.9649 7.62521 10.125 8.46453 10.125 9.5C10.125 9.84518 9.84518 10.125 9.5 10.125C9.15482 10.125 8.875 9.84518 8.875 9.5C8.875 7.77386 10.275 6.37479 12.0008 6.37583Z" />
              <path d="M12 15.75L12.1093 15.7563C12.5755 15.8105 12.9375 16.2068 12.9375 16.6875C12.9375 17.2052 12.5177 17.625 12 17.625C11.4823 17.625 11.0625 17.2052 11.0625 16.6875C11.0625 16.2068 11.4245 15.8105 11.8907 15.7563L12 15.75Z" />
              <path clipRule="evenodd" d="M12 2C6.47732 2 2 6.47732 2 12C2 17.5227 6.47732 22 12 22C17.5227 22 22 17.5227 22 12C22 6.47732 17.5227 2 12 2ZM12 3.25C16.8323 3.25 20.75 7.16768 20.75 12C20.75 16.8323 16.8323 20.75 12 20.75C7.16768 20.75 3.25 16.8323 3.25 12C3.25 7.16768 7.16768 3.25 12 3.25Z" fillRule="evenodd" />
            </svg>
          </button>
        )}
        popoverContent="View recommendations for you"
      />
      {/* Profile */}
      {userData && (      
      <MouseOverPopover 
        mainContent={(
          <div className="flex  gap-3 items-center">
            <button
              type="button"
              onClick={() => setshowProfile(true)}
              // onBlur={() => setshowProfile(false)}
              style={{
                backgroundColor: regex.test(userData?.data?.firstName?.slice(0, 1)?.toUpperCase()) ? '#D2FAF1' : getColorForAlphabet(userData.data.firstName?.slice(0, 1)?.toUpperCase()),
              }}
              className="h-8 w-8 rounded-[8px] relative  flex items-center justify-center cursor-pointer "
            >
              <span className="text-white inbox-message-regular text-[14px]">
                {regex.test(userData.data.firstName?.slice(0, 1)?.toUpperCase()) 
                  ? <RiUserFill className="!text-[#2EB597] !text-[24px] mt-1" />
                  : `${userData.data.firstName?.slice(0, 1)?.toUpperCase()}${userData.data.lastName?.slice(0, 1)?.toUpperCase()}`}
              </span>
             
            </button>
           
          </div> 
        )} 
        popoverContent="Profile"
      />
      )}

      {(showProfile && userData) && (
        <Profile
          profileOptions={profileOptions} 
          regex={regex}
          setshowProfile={setshowProfile}
          showProfile={showProfile}
          handleLogout={handleLogout}
          userData={userData}
        />
      )}
    </div>
  );
}

export default NavbarRight;
