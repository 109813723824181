import React from 'react';

function Button({
  buttonText, onClick,blue, disabled,shadow,icon, 
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`flex h-8 justify-center gap-[5px] items-center w-full text-[0.84rem] tracking-wide transition-all  focus:ring-[3px] hover:shadow ${blue ? 'focus:bg-[#1E47F6]  hover:bg-[#1E47F6]  focus:ring-blue-200    bg-[#466EFF]  text-white hover:transition hover:delay-200 buttonShadow  border-[0.5px] border-blue-700' : ' text-[#1A202c] border bg-white border-gray-200 focus:ring-[#b0c6ff] focus:border-[#466eff]'} disabled:cursor-not-allowed rounded-lg ${shadow ? 'shadow-sm' : 'shadow-md'} medium p-2 disabled:bg-[#466eff8b] disabled:hover:shadow-none disabled:shadow-none`}
    >
      {icon && <span className="">{icon}</span>}

      <span className="text-[0.84rem] medium ">{buttonText}</span>
    </button>
  );
}

export default Button;
