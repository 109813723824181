// tagsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import tagsApi from '../Apis/Tags';

const initialState = {
  tags: [],
  filterData: [], 
  currentData: [],
  loading: false,
  error: null,
  isFilter: false,
  filteredTotalPages: 1,
  newTag: [],
  updatedTag: [],
  page: 0,
  totalPages: 1,
  search: '',
};

export const getAllTags = createAsyncThunk('tags/getAllTags', async ({ page,search }) => {
  const response = await tagsApi.getAllTags(page,search);
  return response.data; // Assuming the data is an array of tags
});

export const createNewTag = createAsyncThunk('tags/createNewTag', async ({
  payload,search,page,createTag, 
},thunkAPI) => {
  const response = await tagsApi.createTag(payload);
  if (response.data.status === 'success') {
    thunkAPI.dispatch(getAllTags({ page,search, createTag }));
  }
  //   console.log('response',response,thunkAPI);
  return response.data; // Assuming the data is the newly created tag
});

export const updateTag = createAsyncThunk('tags/updateTag', async ({ 
  id,payload,search,page,updatetag, 
},thunkAPI) => {
  const response = await tagsApi.updateTag(id,payload);
  console.log('update tag',id,payload);
  if (response.data.status === 'success') {
    thunkAPI.dispatch(getAllTags({ page,search, updatetag }));
  }
  //   console.log('response',response,thunkAPI);
  return response.data; // Assuming the data is the newly created tag
});

export const deleteTag = createAsyncThunk('tags/deleteTag', async ({ 
  id,payload,search,page,deletetag, 
},thunkAPI) => {
  const response = await tagsApi.deleteTag(id);
  console.log('delete tag',id,payload);
  if (response.data.status === 'success') {
    thunkAPI.dispatch(getAllTags({ page,search, deletetag }));
  }
  //   console.log('response',response,thunkAPI);
  return response.data; // Assuming the data is the newly created tag
});

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagSearchData: (state, action) => {
      state.filterData = action.payload;
      state.isFilter = false;
      state.filteredTotalPages = 1;
      // console.log('==here==',action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTag.fulfilled, (state, action) => {
        console.log(action,'in updated');
        state.updatedTag = action.payload.data;
      })
      .addCase(createNewTag.fulfilled, (state, action,thunkAPI) => {
        const { page } = action.meta.arg;
        console.log(action,thunkAPI);
        state.newTag = action.payload.data;
      })
      
      .addCase(getAllTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.loading = false;
        const { search, page,createTag } = action.meta.arg;
        console.log(action,createTag);
           
        if (search) {
          // console.log('is itin search???');
          state.isFilter = true;
          if (page === 1) {
          // Otherwise, set currentData to action.payload
            state.filterData = action.payload.data.tags;
            // state.currentData = action.payload.data.tags;
            // state.page = action.payload.page;
            // state.totalPages = action.payload.totalPages;
          } else if (createTag === 'newTag') {
            state.filterData = [...state.filterData, ...action.payload.data.tags];
            state.filterData.unshift(state.newTag);
          } else {
            state.filterData = [...state.filterData, ...action.payload.data.tags];
            // state.currentData = [...state.currentData, ...action.payload.data.tags];
            // state.page = action.payload.page;
          }
          state.filteredTotalPages = action.payload.data.totalPages;
        } else {
          state.isFilter = false;
          if (page === 1) {
            state.currentData = action.payload.data.tags;
          } else if (createTag === 'newTag') {
            state.currentData = [...state.currentData, ...action.payload.data.tags];
            state.currentData.unshift(state.newTag);
          } else {
            state.currentData = [...state.currentData, ...action.payload.data.tags];
          }
          // state.currentData = [...state.currentData, ...action.payload.data.tags];

          state.page = page;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(getAllTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { setTagSearchData } = tagsSlice.actions;

export default tagsSlice.reducer;
