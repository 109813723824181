import React from 'react';
import { MdCampaign } from 'react-icons/md';

import { AiFillBell } from 'react-icons/ai';
import { FaKey } from 'react-icons/fa6';

import learnotp from '../../../Assets/learnotp.jpg';
import learnutil from '../../../Assets/learnutil.jpg';
import learnmark from '../../../Assets/learnmark.jpg';
import Button from '../../ReusableComponents/Button';

function LearnAboutTemplate({ setLearnAboutModal }) {
  const learnData = [{
    img: learnmark ,
    icon: <MdCampaign className="text-2xl" />,
    type: 'Marketing',
    desc: 'Promotions or information about your business, products or services. Or any message that isn’t utility or authentication.',
    subdesc: 'Examples: welcome messages, newsletters, offers, coupons, catalogs, new store hours.',
  },
  {
    img: learnutil ,
    icon: <AiFillBell className="text-xl" />,
    type: 'Utility',
    desc: 'Messages about a specific transaction, account, order or customer request.',
    subdesc: 'Examples: order confirmations, account updates, receipts, appointment reminders, billing.',
  },
  {
    img: learnotp ,
    icon: <FaKey className="text-xl" />,
    type: 'Authentication',
    desc: 'One-time passwords your customers use to authenticate a transaction or login.',
    subdesc: 'Examples: one-time password, account recovery code.',
  }];
  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-[5px]">
        <span className="text-lg text-[#1A202C] medium">Learn more about categories.</span>
        <span className="text-[.8rem] text-[#555C67] ">
          To make sure your message template gets approved, choose a category that describes the text, media and buttons you will send. The category also sets the price per conversation. 
          {/* <button type="button" onClick={() => setLearnAboutModal(true)} className="text-blue-600 hover:cursor-pointer hover:text-blue-500">  Learn more about categories.</button> */}
        </span>
      </div>
      <div className="flex gap-4">
        { learnData?.map((e) => (
          <div className="h-[410px] border-[1px] w-60 rounded-lg shadow-md overflow-hidden">
            <div className="h-[40%] ">
              <img className="h-full w-full" src={e.img} alt="" />
            </div>
            <div className="h-[60%] flex flex-col border-t-[1px] p-4 gap-2 bg-[#FAFBFD]">
              <span className="text-[#1A202C] text-[15px] medium flex items-center gap-1">
                {e.icon} 
                {' '}
                {e.type}
              </span>
              <span className="text-[#1A202C] text-sm">{e.desc}</span>
              <span className="text-[#555c67] text-[.86rem]">{e.subdesc}</span>

            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full ">
        <div className=" w-14 ">

          <Button buttonText="Close" blue onClick={() => setLearnAboutModal(false)} />

        </div>
      </div>
    </div>
  );
}

export default LearnAboutTemplate;
