// companySlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import companyApi from '../Apis/Company';

const initialState = {
  data: null,
  isLoading: false,
  isError: false,
  companyName: '',
};

// Define an async thunk action creator
export const fetchCompanyById = createAsyncThunk(
  'data/fetchCompanyById', // Action type
  async (id, thunkAPI) => {
    const response = await companyApi.getCompanyById(id);
    return response.data;
  },
);

export const uploadFile = createAsyncThunk(
  'data/uploadFile', // Action type
  async (data, thunkAPI) => {
    const response = await companyApi.uploadFile(data);
    return response.data;
  },
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
        // console.log(action.payload.data.businessDetails.title);
        state.companyName = action.payload.data.businessDetails.title;
      })
      .addCase(fetchCompanyById.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
  
export default companySlice.reducer;
