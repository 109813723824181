/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import './ColorPicker.css';
import { HexColorPicker, HexColorInput } from 'react-colorful';

function ColorPicker({ color,setColor }) {
  const [focus, setFocus] = useState(false);
  const [showPicker, setshowPicker] = useState(false);
  // const [color, setColor] = useState('#1A202c');
  const closePopUp = (ref) => {
    useEffect(() => {     
      const handleClickOutside = (event) => {
        // console.log('ref event',event,ref);
        if (ref.current && !ref.current.contains(event.target)) {
          setshowPicker(false);
          // alert('clicked outside');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  closePopUp(wrapperRef);

  return (
    <div ref={wrapperRef} className="custom-layout custom-pointers w-max relative h-10 ">
      <div className="h-full flex items-center ">
        <div className={`h-[34px] w-[217px] bg-white rounded-lg ${focus ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff] ' : 'hover:border-[#9EA9B8]'}  shadow-sm border pl-3   px-2 flex items-center text-xs`}>
          #
          <HexColorInput onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} className=" w-[90%] placeholder:text-[13.5px]  outline-none text-[12px] text-[#292E36] " color={color} onChange={setColor} />
          <button type="button" onClick={() => setshowPicker(!showPicker)} className=" h-full w-[10%]">
            <div style={{ backgroundColor: color }} className="h-4 w-4 rounded shadow hover:scale-[1.20] transition-all ease-in-out duration-200" />
          </button>
        </div>
      </div>

      {/* <section className="custom-layout">
        <RgbaColorPicker color={color} onChange={setColor} />
      </section> */}
      {showPicker && <HexColorPicker className="absolute -top-[195px]" color={color} onChange={setColor} />}
    </div>
  );
}

export default ColorPicker;
