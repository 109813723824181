import React from 'react';
import CustomSwitch from '../../ReusableComponents/CustomSwitch';

const PermissionList = ({
  value,setValue,label,
}) => (
  <div>
    <div className="flex justify-between items-center">
      <div className="text-sm text-[#292E36]">
        {label}
      </div>
      <CustomSwitch
        value={value}
        setValue={setValue}
        switchOn="On"
        switchOff="Off"
        name="manageSettings"
        label={label}
      />
    </div>
  </div>
);

export default PermissionList;
