import React from 'react';
import SelectOptions from '../../ReusableComponents/SelectOptions';

const CTLanguageSection = () => (
  <div className="pl-5 pr-3 py-5">
    <div className="text-base text-[#1A202C] ">
      Languages
    </div>
    <div className="mt-4 space-y-4">
      <div className="h-10  flex items-center pl-5 text-sm medium rounded-md text-[#1E47F6] bg-[#b0c6ff3d]">
        English
      </div>
      <div>
        <SelectOptions label="Add Languages" size="small" />

      </div>
    </div>
  </div>
);

export default CTLanguageSection;
