import axios from 'axios';

console.log('===Interceptor is running');
// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do something with the error
    if (error.response) {
      console.log('Interceptor is running>>>>>>>');
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const statusCode = error.response.status;

      // Customize error handling based on status code
      switch (statusCode) {
        case 401:
          // Handle unauthorized errors
          console.error('Unauthorized error:', error.response.data);
          break;
        case 404:
          // Handle not found errors
          console.error('Not found error:', error.response.data);
          break;
        // Add more cases for other status codes as needed
        default:
          console.error('Generic error:', error.response.data);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Generic error:', error.message);
    }

    // Return the rejected Promise to maintain the error propagation
    return Promise.reject(error);
  },
);

// Now, every Axios request in your application will be intercepted by this error handler
export default axios;
