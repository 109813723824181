import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CTStep1 from './CTStep1';
import CTStep2 from './CTStep2';
import { createTemplates } from '../../../slice/templateSlice';

export default function CreateTemplates() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  const [Name, setName] = useState('');
  const [Category, setCategory] = useState('');
  const [Language, setLanguage] = useState('');
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [footer, setFooter] = useState(null);
  const [buttons, setButtons] = useState([]);

  // console.log(Category,'category');

  const payload = {
    name: Name,
    category: Category,
    language: Language,
    components: [
      header,
      body,
      ...(buttons.length > 0 ? [{ type: 'BUTTONS', buttons }] : []),
    ],
  };
  const handleCreateTemplate = async () => {
    // const payload = {
    //   name: Name,
    //   category: Category,
    //   language: Language,
    //   components: [
    //     header,
    //     body,
    //     ...(buttons.length > 0 ? [{ type: 'BUTTONS', buttons }] : []),
    //   ],
    // };
    console.log('payload',payload);

    const res = await dispatch(createTemplates(payload)).unwrap();
    console.log('handleCreateTemplate res',res);
  };

  if (step === 1) {
    return (
      <CTStep1
        step={step}
        setStep={setStep}
        setName={setName}
        Name={Name}
        Category={Category}
        setCategory={setCategory} 
        Language={Language}
        setLanguage={setLanguage}
      />
    ); 
  }
  
  if (step === 2) {
    return (
      <CTStep2 
        header={header}
        setHeader={setHeader}
        body={body}
        setBody={setBody}
        Language={Language} 
        setLanguage={setLanguage}
        footer={footer}
        setFooter={setFooter}
        buttons={buttons}
        setButtons={setButtons}
        handleSubmit={handleCreateTemplate}
        payload={payload}
      />
    ); 
  }
}
