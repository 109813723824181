import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

export default function CreateCampaignMenu({
  buttonText,
  createCampaignOptions,icon,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => navigate('/marketing/templates')}
        // variant="contained"
        className="bg-[#4c76e0] flex items-center h-[50px] min-w-[193px] space-x-2 text-white p-3 px-4 rounded-[10px] inbox-message-regular "
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        // onClick={handleClick}
      >
        
        {/* <img className="w-5 h-5 !text-white " src={bot} alt="" /> */}
        {icon && <span>{icon}</span>}
        <span>{buttonText}</span>
      </button>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {createCampaignOptions.map((menu) => (
          <Link
            to={menu.link}
            key={menu.menu}
          >
            <ListItemButton className="!px-5 mr-7" key={menu.menu}>
              <ListItem
                alignItems="flex-start"
                sx={{ padding: '0px' }}
              >
                <ListItemAvatar sx={{ fontSize: '25px' }} style={{ color: 'black' }}>
                  {menu.icon}
                </ListItemAvatar>
                <ListItemText
                  primary={menu.menu}
                  secondary={menu.desc}
                  style={{
                    color: 'black',maxWidth: '250px',fontFamily: 'GraphikRegular',fontSize: '14px', 
                  }}
                />
              </ListItem>
            </ListItemButton>
          </Link>
        ))}
      </Menu> */}
      {/* <Outlet /> */}
    </div>
  );
}
