import React from 'react';
import cmg from '../../../Assets/comingsoon.png';

function DigitalMonitoring() {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <img className="w-[600px] aspect-auto rounded-xl broder shadow-lg" src={cmg} alt="" />
    </div>
  );
}

export default DigitalMonitoring;
