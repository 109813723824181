import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import EmployeeInput from './EmployeeInput';
import Button from '../../ReusableComponents/Button';
import { checkUsersByEmail, createUsers } from '../../../slice/userSlice';
import { baseURL, companyId } from '../../../slice/HEADERS';
import PermissionsComponent from './Permissions';

const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const initialPermissions = {
  administrative: {
    manageSettings: false,
    manageUsers: false,
    viewRolePermissions: false,
    editRolePermissions: false,
  },
  messenger: {
    createNewConversations: false,
    myConversations: false,
    conversationsAssignedToOthers: false,
    unassignedConversations: false,
    manageTemplates: false,
    deleteMessage: false,
  },
  reviews: {
    viewReviews: false,
    respondToReviews: false,
  },
  reporting: {
    basicReports: false,
    exportReports: false,
  },
  webchat: {
    viewWebchatSettings: false,
    appearanceAndAutoResponder: false,
    locationPicker: false,
    onlineForms: false,
    hostedForms: false,
  },
  automations: {
    viewAutomations: false,
    manageAutomations: false,
    manageUserMappings: false,
  },
  campaigns: {
    viewCampaigns: false,
    createNewCampaigns: false,
    sendCampaigns: false,
    exportMarketingList: false,
    marketingAutomationsAccess: false,
  },
};

const InviteUsers = ({ setInviteUsersModel }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('TEAM_MEMBER'); // 'OWNER', 'MANAGER', 'TEAM_MEMBER'
  const [isEmailValid, setIsEmailValid] = useState(true);
  // const { data, isLoading, isError } = useSelector((state) => state.users.createdUser);
  const [userExists, setUserExists] = useState(false);
  const [permissions, setPermissions] = useState(initialPermissions);
  // console.log(data, isLoading, isError);
  let debounceTimeout;

  console.log('isEmailValid',isEmailValid);
  // Handle input changes
  const handleInputChange = (e) => {
    const { value } = e.target;

    if (value?.length > 0) {
      if (value.match(regEx)) {
        setIsEmailValid(true);      
      } else {
        setIsEmailValid(false);
      }
    } else {
      setIsEmailValid(true);
    }
    
    setEmail(value);
  };

  const handleInviteUsers = async () => {
    const payload = {
      email,
      accessLevel: role,
      permissions,
    };
    const res = await dispatch(createUsers(payload)).unwrap();
    console.log('res',res);
    if (res.status === 'success') {
      toast.success(res.message);
      setPermissions(initialPermissions);
      setInviteUsersModel(false);
    }
    if (res.status === 'failure') {
      toast.success(res.message);
    }
  };
  
  return (
    <div>
      <div className="semibold text-[#12151A] text-[17px] mb-5 border-b">
        Invite your team to Elyzian.
      </div>

      <div>
        <div className="text-sm">
          Happy employees make for a successful business. 
          Invite your team to simplify communication and save time, 
          whether you’re texting customers or answering their calls.
        </div>
        <EmployeeInput 
          email={email}
          setEmail={setEmail}
          role={role}
          setRole={setRole} 
          handleInputChange={handleInputChange}
          isEmailValid={isEmailValid}
        />
      </div>

      <div className="h-[400px] overflow-y-auto">
        <PermissionsComponent 
          role={role}
          permissions={permissions} 
          setPermissions={setPermissions}
        />
      </div>

      <div className="flex flex-end">
        <Button
          buttonText="Send invitations" 
          disabled={!(email?.length > 0 && isEmailValid)}
          blue={email?.length > 0 && isEmailValid}
          onClick={handleInviteUsers}
        />
      </div>
    </div>
  );
};

export default InviteUsers;
