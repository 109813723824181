import React from 'react';
import Input from '../../../ReusableComponents/Input';
import ColorPicker from '../../../ReusableComponents/ColorPicker/ColorPicker';

function CustomizeBody({
  headerText, 
  setHeaderText,
  headerSubText,
  setHeaderSubText,
  headerColor,
  setHeaderColor,
  buttonColor, 
  setButtonColor,
}) {
  return (
    <div className="px-[45px] pt-[80px]">
      <div className="flex flex-col gap-9">
        <span className="text-2xl medium text-[#1A202c]">Customize how it looks</span>
        <span className="text-[#7b8994] text-sm">At any time, you can change the customizations in your Webchat settings.</span>
      </div>
      <div className="my-[35px]  flex flex-1 gap-10 flex-col">
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Header</span>
          <div className="w-[60%]">  
            <Input
              type="text"
              limit
              setLimit="20"
              value={headerText}
              onChange={(e) => setHeaderText(e.target.value)}
        // setValue={setFooterText}
            //   placeholder={``}
            />

          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Header Sub Text</span>
          <div className="w-[60%]">  
            <Input
              type="text"
              limit
              setLimit="30"
              value={headerSubText}
              onChange={(e) => setHeaderSubText(e.target.value)}
        // setValue={setFooterText}
            //   placeholder={``}
            />

          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Header Color</span>
          <div className="w-[60%] flex justify-end">  
            <ColorPicker color={headerColor} setColor={setHeaderColor} />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Button Color</span>
          <div className="w-[60%] flex justify-end">  
            <ColorPicker color={buttonColor} setColor={setButtonColor} />
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default CustomizeBody;
