import React from 'react';
import NavTabs from './NavTabs';
import NavbarRight from './NavbarRight';

function Navbar() {
  return (
    <div className="flex items-center justify-between shadow-md tracking-wide semibold  h-[3.5rem] text-[#9EA9B8] bg-[#12151A]">
      <NavTabs />
      <NavbarRight />
    </div>
  );
}

export default Navbar;
