import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateTemplates from './components/Marketing/TemplatesComponents/CreateTemplates';
import WebchatScript from './components/WebsiteTools/Webchat/WebchatComponents/WebchatScript';
import WebchatBubble from './components/WebsiteTools/Webchat/WebchatComponents/WebchatBubble';
import WebchatBody from './components/WebsiteTools/Webchat/WebchatComponents/WebchatBody';
import YourBusiness from './components/Settings/Business/YourBusiness';
import ProfilePage from './components/Settings/Profile/ProfilePage';
import Users from './components/Settings/Users/Users';
import BusinessHours from './components/Settings/Business/BusinessHours';
import BusinessListings from './components/DigitalShowroom/DigitalComponents/BusinessListings';
import DigitalBookings from './components/DigitalShowroom/DigitalComponents/DigitalBookings';
import SocialPlanner from './components/DigitalShowroom/DigitalComponents/SocialPlanner';
import SocialPosts from './components/DigitalShowroom/DigitalComponents/SocialPosts';
import DigitalMonitoring from './components/DigitalShowroom/DigitalComponents/DigitalMonitoring';
import SocialChannels from './components/DigitalShowroom/DigitalComponents/SocialChannels';
import Calendar from './components/DigitalShowroom/SocialPlanner/Calendar';
import AllCampaigns from './components/Marketing/MarketingComponents/AllCampaigns';
import Ads from './components/Ads/Ads';

// Lazy-loaded route components
const Home = lazy(() => import('./components/Home/Home'));
const Inbox = lazy(() => import('./components/Inbox/Inbox'));
const AllConversation = lazy(() => import('./components/Inbox/AllConversation'));

const Contacts = lazy(() => import('./components/Contacts/Contacts'));
const Marketing = lazy(() => import('./components/Marketing/Marketing'));
const Templates = lazy(() => import('./components/Marketing/Templates'));

const AllReviews = lazy(() => import('./components/Reviews/AllReviews'));
const Reviews = lazy(() => import('./components/Reviews/Reviews'));

const Reporting = lazy(() => import('./components/Reporting/Reporting'));
const Automations = lazy(() => import('./components/Automations/Automations'));

const Settings = lazy(() => import('./components/Settings/Settings'));
// const ProfilePage = lazy(() => import('./components/Settings/Profile/ProfilePage'));
// const Users = lazy(() => import('./components/Settings/Users/Users'));
const AllUsers = lazy(() => import('./components/Settings/Users/AllUsers'));
const WebsiteTools = lazy(() => import('./components/WebsiteTools/WebsiteTools'));
const Webchat = lazy(() => import('./components/WebsiteTools/Webchat/Webchat'));
const ReviewAnalytics = lazy(() => import('./components/Reviews/ReviewAnalytics'));

// const WebchatScript = lazy(() => import('./components/WebsiteTools/Webchat/WebchatComponents/WebchatScript'));
// const WebchatBubble = lazy(() => import('./components/WebsiteTools/Webchat/WebchatComponents/WebchatBubble'));
// const WebchatBody = lazy(() => import('./components/WebsiteTools/Webchat/WebchatComponents/WebchatBody'));

const DigitalShowroom = lazy(() => import('./components/DigitalShowroom/DigitalShowroom'));

const PageNotFound = lazy(() => import('./components/ReusableComponents/PageNotFound'));

function AppRoutes({ userId }) {
  return (
    <div className="w-full h-full">
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>        
          {/* <Route path="/onboarding" element={<Onboarding />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/inbox" element={<Inbox />}>
            <Route path="all-conversation" element={<AllConversation assignedUser="all" />} />
            <Route path="assigned-to-you" element={<AllConversation assignedUser={userId} />} />
            <Route path="unassigned" element={<AllConversation assignedUser="unassigned" />} />
          </Route>
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/marketing" element={<Marketing />}>
            <Route path="all-campaigns" element={<AllCampaigns />} />
            <Route path="templates" element={<Templates />} />
            <Route path="message-templates" element={<CreateTemplates />} />
          </Route>

          <Route path="/reviews" element={<AllReviews />}>
            {/* <Route index element={<Reviews />} /> */}
            <Route path="all-reviews" element={<Reviews />} />
            <Route path="needs-response" element={<Reviews />} />
            <Route path="review-analytics" element={<ReviewAnalytics />} />
          </Route>

          <Route path="/automations" element={<Automations />} />
          <Route path="/reports" element={<Reporting />} />

          <Route path="/account/settings" element={<Settings />}>
            <Route index element={<ProfilePage />} />           
            <Route path="your-profile" element={<ProfilePage />} />
            <Route path="users" element={<Users />} />
            <Route path="your-business" element={<YourBusiness />} />
            <Route path="your-business/business-hours" element={<BusinessHours />} />          

            {/* <Route path="users/all-users" element={<AllUsers />} /> */}
          </Route>

          <Route path="/website-tools" element={<WebsiteTools />} />

          <Route path="/website-tools/webchat" element={<Webchat />} />
          {/* <Route index element={<WebchatScript />} />
            <Route path="script-code" element={<WebchatScript />} />
            <Route path="webchat-bubble" element={<WebchatBubble />} />
            <Route path="webchat-body" element={<WebchatBody />} /> */}
          {/* <Route path="/website-tools/webchat/script-code" element={<WebchatScript />} />
          <Route path="/website-tools/webchat/webchat-bubble" element={<WebchatBubble />} />
          <Route path="/website-tools/webchat/webchat-body" element={<WebchatBody />} /> */}

          <Route path="/digital-showroom" element={<DigitalShowroom />}>
            {/* <Route index element={<BusinessListings />} /> */}
            <Route path="business-listings" element={<BusinessListings />} />
            <Route path="bookings" element={<DigitalBookings />} /> 
            <Route path="social-planner" element={<Calendar />} />
            <Route path="post-comments" element={<SocialPosts />} />
            <Route path="monitoring" element={<DigitalMonitoring />} />
            <Route path="integrate-channels" element={<SocialChannels />} />
          </Route>
          <Route path="/ads" element={<Ads />} />

          <Route path="*" element={<PageNotFound />} />
         
        </Routes>
      </Suspense>
    </div>
  );
}

export default AppRoutes;
