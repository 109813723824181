/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// if you want to add a color, just add it in the 'color' object as a key:value pair
// dont touch anything else!!
export const boxShadowStyle = {
  boxShadow:
    'rgba(18, 21, 26, 0.08) 0px 0px 0px 0.5px, rgba(18, 21, 26, 0.04) 0px 4px 8px, rgba(18, 21, 26, 0.04) 0px 8px 16px -2px, rgba(18, 21, 26, 0.04) 0px 16px 32px -4px',
};
const color = {
  grey_dark: '#3e4956',
  grey_primary: '#b6b8b9',
  grey_light: '#dedede',
  blue_dark: '#10253f',
  blue_grey: '#192d45',
  blue_primary: '#3975cb',
  blue_tag: '#cee2f3',
  blue_light: '#a0c7ff',
  medium_gray: 'gray',
  black_primary: '#000000',
  white_primary: '#ffffff',
  white_blue: '#ecf5ff',
  blue_hover: 'e6f0ff',
  green_rating: 'green',
  yellow_rating: 'yellow',
  red_rating: 'red',
  gray_special: '#555555',
  blue_lighter: '#f9fafc',
  golden_star: '#f47d02',
  
};
export default color;

export const colorStyle = {};

for (const key in color) {
  const property = `--${key}`;
  colorStyle[property] = color[key];
}
const characterColors = {
  A: '#FF5252',
  B: '#FFD600',
  C: '#008CBA',
  D: '#4CAF50',
  E: '#BA68C8',
  F: '#FF5722',
  G: '#F44336',
  H: '#9C27B0',
  I: '#3F51B5',
  J: '#FF9800',
  K: '#009688',
  L: '#673AB7',
  M: '#2196F3',
  N: '#795548',
  O: '#FFC107',
  P: '#361500',
  Q: '#9E9E9E',
  R: '#00BCD4',
  S: '#E91E63',
  T: '#3F51B5',
  U: '#8BC34A',
  V: '#673AB7',
  W: '#FFEB3B',
  X: '#4CAF50',
  Y: '#FF5722',
  Z: '#9C27B0',
  '@': '#FF5252',
  '&': '#4CAF50',
  '*': '#FFD600',
  '#': '#E91E63',
  '%': '#9C27B0',
  '+': '#3F51B5',
  '-': '#795548',
  '/': '#FF5722',
  ':': '#FFC107',
  ';': '#2196F3',
  '<': '#F44336',
  '>': '#00BCD4',
  '?': '#607D8B',
  '!': '#FFEB3B',
  '=': '#8BC34A',
  '(': '#673AB7',
  ')': '#FF9800',
  '{': '#9E9E9E',
  '}': '#FF5252',
  '[': '#FF5722',
  ']': '#3F51B5',
  '|': '#E91E63',
  '\\': '#795548',
};

export const alphabetColors = {
  A: '#E63946',
  B: '#87CBB9',
  C: '#0EA293',
  D: '#457B9D',
  E: '#1D3557',
  F: '#FFC300',
  G: '#FF5733',
  H: '#C70039',
  I: '#900C3F',
  J: '#FFC107',
  K: '#FF9800',
  L: '#FFA07A',
  M: '#7E22DF',
  N: '#2C3E50',
  O: '#3498DB',
  P: '#27AE60',
  Q: '#16A085',
  R: '#2980B9',
  S: '#D83880',
  T: '#34495E',
  U: '#2ECC71',
  V: '#9B59B6',
  W: '#2C3E50',
  X: '#F39C12',
  Y: '#D35400',
  Z: '#8B0000',
  '@': '#FFA07A',
  '&': '#16A085',
  '*': '#E67E22',
  '#': '#2980B9',
  '%': '#9B59B6',
  '+': '#FF5733',
  '-': '#1D3557',
  '/': '#E63946',
  ':': '#FFC300',
  ';': '#C70039',
  '<': '#FFA07A',
  '>': '#F39C12',
  '?': '#2C3E50',
  '!': '#900C3F',
  '=': '#FF9800',
  '(': '#3498DB',
  ')': '#27AE60',
  '{': '#16A085',
  '}': '#8E44AD',
  '[': '#2980B9',
  ']': '#D35400',
  '|': '#FF5733',
  '\\': '#2ECC71',
};

export function generateAlphabetColorMap(alphabetcolor) {
  // Initialize an empty object to hold the alphabet-color map
  const colorMap = {};

  // Iterate over the entries of the provided object using Object.entries
  Object.entries(alphabetcolor).forEach(([alphabet, c]) => {
    // Add the color to the object with the corresponding alphabet as key
    colorMap[alphabet] = c;
  });

  // Return a function that generates the specific color for a given alphabet
  return function (alphabet) {
    return colorMap[alphabet];
  };
} 

export const getColorForAlphabet = generateAlphabetColorMap(alphabetColors);

export function generateAlphabetColorMap2(characterColor) {
  // Initialize an empty object to hold the alphabet-color map
  const colorMap = {};

  // Iterate over the entries of the provided object using Object.entries
  Object.entries(characterColor).forEach(([alphabet, c]) => {
    // Add the color to the object with the corresponding alphabet as key
    colorMap[alphabet] = c;
  });

  // Return a function that generates the specific color for a given alphabet
  return function (alphabet) {
    return colorMap[alphabet];
  };
} 

export const getColorForAlphabet2 = generateAlphabetColorMap2(characterColors);
