import React, { useState } from 'react';

import Input from '../../../ReusableComponents/Input';
import ColorPicker from '../../../ReusableComponents/ColorPicker/ColorPicker';
import ImageUpload from '../../../ReusableComponents/ImageUpload';
import Button from '../../../ReusableComponents/Button';

function Customizebubble({
  greeting, setGreeting,color, setColor, imageUrl,
  setImageUrl, setActiveTab,setIconType,icons,

}) {
  return (
    <div className="px-[45px] pt-[80px] pb-10">
      <div className="flex flex-col gap-9">
        <span className="text-2xl medium text-[#1A202c]">Customize how it looks</span>
        <span className="text-[#7b8994] text-sm">At any time, you can change the customizations in your Webchat settings.</span>
      </div>
      <div className="my-[35px]  flex flex-1 gap-10 flex-col">
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Greeting</span>
          <div className="w-[60%]">  
            <Input
              type="text"
              limit
              setLimit="65"
              value={greeting}
              onChange={(e) => setGreeting(e.target.value)}
        // setValue={setFooterText}
            //   placeholder={``}
            />

          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Image</span>
          <div className="w-[60%] flex justify-end">  
            <div className="w-max">
              <ImageUpload
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Color</span>
          <div className="w-[60%] flex justify-end">  
            <ColorPicker color={color} setColor={setColor} />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#292E36] medium">Icon</span>
          <div className="w-[60%] flex justify-end">  
            <div className="flex gap-3">
              {icons?.map((e) => (<button key={e.icon_id} onClick={() => setIconType(e.icon_id)} type="button" className="w-12 h-12 text-[20px] text-[#63727d] focus:text-[#466EFF] fill-[#63727d] focus:fill-[#466EFF] focus:bg-[#f2f9fd] rounded-full flex justify-center items-center border border-[#9EA9B8] bg-white button-shadow  focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff]">{e.icon}</button>))}
            </div>

          </div>
        </div>
      </div>
      <div>
        <Button
          onClick={() => setActiveTab('webchat-body')}
          blue
          buttonText="Continue"
        />
      </div>
    </div>
  );
}

export default Customizebubble;
