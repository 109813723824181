export const token = localStorage.getItem('authToken');
export const baseURL = 'https://dev.api.elyzian.in/api';
// export const baseURL = 'http://localhost:8000/api';
export const companyId = localStorage.getItem('compId');

export const config = {
  Accept: 'application/json',
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const fileConfig = {
  Accept: 'application/json',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: (progressEvent) => {
    console.log('onUploadProgress', progressEvent.loaded);
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(`Upload progress: ${percentCompleted}%`);
  },
};
