/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useEffect, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
// import './calendar.css';

const Calendar = () => {  
  const calendarRef = useRef(null);

  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().setOption('eventOrder', (a, b) => {
        if (a.type === b.type) {
          return 0;
        }
        return a.type === 'event' ? -1 : 1;
      });
    }
  }, []);

  const handleDateSelect = (selectInfo) => {
  
  };

  function renderEventContent(eventInfo) {
    return (
      <div>hi</div>
    );
  }
  
  const handleDayCellDidMount = (info) => {
    const parentDivs = document.querySelectorAll('.fc-daygrid-day-top');
    parentDivs.forEach((parentDiv) => {
      const anchorTag = parentDiv.querySelector('.fc-daygrid-day-number');
      const newDiv = parentDiv.querySelector('.my-class');
      const newButton = document.createElement('button');
      if (!newDiv) {
        const newElement = document.createElement('div');
        newElement.classList.add('my-class');
        anchorTag.insertAdjacentElement('afterend', newElement);
          
        newButton.innerHTML = '+';
        newButton.classList.add('add-event-button');
        newButton.style.visibility = 'hidden';
        newElement.appendChild(newButton);
      }
      const frame = parentDiv.closest('.fc-daygrid-day-frame');
      frame.addEventListener('mouseover', () => {
        newButton.style.visibility = 'visible';
      });
      frame.addEventListener('mouseout', () => {
        newButton.style.visibility = 'hidden';
      });
      newButton.addEventListener('click', () => {
        console.log('Button clicked!');
        // setOpenPostModal(true);
      });
    });  
  };

  const handleEvents = (event) => {
    setCurrentEvents(event);
  };

  return (
    <div className=" p-10 w-full h-full overflow-x-hidden">
    
      <div className="calendar-container">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={[
            { title: 'Holi', date: '2024-03-24' },
            { title: 'Whatsapp Campaign', date: '2024-03-02' },
            { title: 'Whatsapp Campaign', date: '2024-03-10' },
            { title: 'Appointment Booked', date: '2024-03-16' },
            { title: 'Instagram Post scheduled', date: '2024-03-19' },
            // Add more events as needed
          ]}
        />
      </div>
     
    </div>
  ); 
};

export default Calendar;
