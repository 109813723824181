import axios from 'axios';
import { baseURL,companyId, config } from '../slice/HEADERS';

const getappointments = (page) => axios.get(`${baseURL}/leads/get-appointment/${companyId}?page=${page}&pageSize=20`,config);

const appointmentApi = {
  getappointments,
};
    
export default appointmentApi;
