import React from 'react';
import CreateCampaignMenu from './CreateCampaignMenu';

function AllCampaigns() {
  const type = 'campaign';
  return (
    <div className="w-full h-full flex justify-center ">
      <div className="shadow-md w-[80%] flex h-[300px] mt-[10%] bg-[#112D4E] rounded-[22px] p-6">
   
        <div className="w-[70%] space-y-4 ">
          <div className="text-[#9ea9b8] inbox-message capitalize text-[12px]">{type}</div>
          <div className=" text-white text-[26px]">
            Try creating your first
            {' '}
            {type}
            {' '}
            to see what it&apos;s like
          </div>
          <div className="text-[13px] text-[#c8cfd9] inbox-message-regular">
            Explore how easy it is to create a 
            {' '}
            {type}
            {' '}
            and personalize your 
            {' '}
            {type}
            {' '}
            for
            everyone on your list.
          </div>
          <div className="!mt-6">
            <CreateCampaignMenu
              buttonText={`Create ${type}`}
            />
          </div>
        </div>
        <div className="w-[30%] flex flex-col  ">
          <div className="bg-[#4c76e0] scale-in-br-automation self-end rounded-t-[30px] rounded-bl-[30px] rounded-br-[8px]  w-[70%] h-[110px]" />

          <div className="bg-white scale-in-bl-automation border-[1px] absolute rounded-t-[30px] rounded-br-[30px] rounded-bl-[8px] mt-[120px] ml-[10px]  w-[162px] h-[72px]" />
          <div className="bg-white scale-in-bl-automation1 border-[1px] absolute ml-[50px] rounded-t-[25px] rounded-br-[25px] rounded-bl-[8px] mt-[140px]  w-[152px] h-[67px]" />
          <div className="bg-white scale-in-bl-automation2 border-[1px] absolute ml-[30px] rounded-t-[30px] rounded-br-[30px] rounded-bl-[8px] mt-[160px] w-[142px] h-[72px]" />
      
        </div>

      </div>
    </div>
  );
}

export default AllCampaigns;
