import React, { useState } from 'react';
import Button from './Button';

function ImageUpload({ imageUrl,setImageUrl }) {
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    // Trigger the input file click event
    document.getElementById('imageInput').click();
  };

  return (
    <div className="w-full h-full flex items-center gap-3">
      <div
        className="w-11 h-11 rounded-full"
        style={{
          backgroundImage: imageUrl ? `url(${imageUrl})` : `url(${'https://assets.podium.com/images/defaultWebchatPinkAvatar.png'})`,
          backgroundSize: 'cover',
        }}
      />
      <div className="min-w-[80px]">
        <Button shadow buttonText="Edit Image" onClick={handleButtonClick} />
        {/* Hidden input element for image upload */}
        <input
          type="file"
          id="imageInput"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </div>
    </div>
  );
}

export default ImageUpload;
