import React, { useEffect, useState } from 'react';
import Input from '../../ReusableComponents/Input';

const CTFooter = ({ footer, setFooter, Language }) => {
  const [footerText, setFooterText] = useState('');
  useEffect(() => {
    setFooter({
      type: 'FOOTER',
      text: footerText,
    });
  },[footerText]);
  
  return (
    <div>
      <div className="text-sm medium flex items-center">
        Footer
        <span className="text-[0.7rem] bg-gray-100 py-[1px] ml-3 rounded-lg px-1 text-center">Optional</span>
      </div>
      <p className="text-[#1c2b33a6] text-[13px] my-3">Add a short line of text to the bottom of your message template.</p>
      
      <Input
        type="text"
        limit
        setLimit="1024"
        value={footerText}
        onChange={(e) => setFooterText(e.target.value)}
        // setValue={setFooterText}
        placeholder={`Enter text in ${Language}`}
      />
    </div>
  ); 
};

export default CTFooter;
