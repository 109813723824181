import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reviewApi from '../Apis/Reviews';

const initialState = {
  reviews: [],
  filterData: [], 
  currentData: [],
  loading: false,
  error: null,
  isFilter: false,
  filteredTotalPages: 1,

  page: 0,
  totalPages: 1,
  search: '',
};

// Create an async thunk for fetching all contacts
// export const fetchAllReviews = createAsyncThunk('reviews/fetchAll', async () => {
//   const response = await reviewApi.getAllReviews();
//   // console.log('review response',response);
//   return response.data; 
// });
export const getAllReviews = createAsyncThunk('reviews/getAllReviews', async ({
  types ,search,page,range, min,max,
}) => {
  const response = await reviewApi.getDBReviews(types,search,page,range,min,max);
  // console.log('review response',response.data);
  return response.data; 
});

export const replyById = createAsyncThunk('reviews/replyById', async ({ payload }) => {
  const response = await reviewApi.replyById(payload);
  // console.log('review reply response',response,payload);
  return response.data; 
});

export const reviewAnalytics = createAsyncThunk('reviews/reviewAnalytics', async () => {
  const response = await reviewApi.reviewAnalytics();
  // console.log('review reply response',response,payload);
  return response.data; 
});
// Create the initial state for your reviews slice

// Create the review slice
const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setreviewSearchData: (state, action) => {
      state.filterData = action.payload;
      state.isFilter = false;
      state.filteredTotalPages = 1;
      // console.log('==here==',action);
    },
    
    setreviewTypeData: (state, action) => {
      state.page = 0;
      state.currentData = action.payload;
      state.filterData = action.payload;
      state.isFilter = false;
      state.filteredTotalPages = 1;
      // console.log(state,action,'setreviewTypeData');
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchAllReviews.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchAllReviews.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.reviews = action.payload;
      // })
      // .addCase(fetchAllReviews.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      .addCase(replyById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(replyById.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload,'action');
        if (action.payload.data) {
          const { updatedReview } = action.payload.data;
          // Find the index of the review with the matching id
          const index = state.currentData.findIndex((review) => review.reviewId === updatedReview.reviewId);
          const filteredIndex = state.filterData.findIndex((review) => review.reviewId === updatedReview.reviewId);
          // If found, update the review in the array
          if (index !== -1) {
            state.currentData[index] = updatedReview;
          }
          if (filteredIndex !== -1) {
            state.filterData[index] = updatedReview;
          }
        }
        
        state.error = false;
      })
      .addCase(replyById.rejected, (state,action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.loading = false;
        const { search, page ,range } = action.meta.arg;
        // console.log(action);
        // if (action.meta.arg === '') {
        //   // If meta.arg (search) is an empty string, set currentData to action.payload
        //   state.reviews = action.payload;
        //   state.currentData = action.payload;
        // }
        // state.isError = false;
      
        if (search) {
          // console.log('is itin search???');
          state.isFilter = true;
          if (page === 1) {
          // Otherwise, set currentData to action.payload
            state.filterData = action.payload.reviews;
            // state.currentData = action.payload.reviews;
            // state.page = action.payload.page;
            // state.totalPages = action.payload.totalPages;
          } else {
            state.filterData = [...state.filterData, ...action.payload.reviews];
            // state.currentData = [...state.currentData, ...action.payload.reviews];
            // state.page = action.payload.page;
          }
          state.filteredTotalPages = action.payload.totalPages;
        } else {
          state.isFilter = false;
          if (page === 1) {
            state.currentData = action.payload.reviews;
          } else {
            state.currentData = [...state.currentData, ...action.payload.reviews];
          }
          // state.currentData = [...state.currentData, ...action.payload.reviews];

          state.page = page;
          state.totalPages = action.payload.totalPages;
        }
      })
      .addCase(getAllReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { setreviewSearchData,setreviewTypeData } = reviewSlice.actions;

// export { fetchAllReviews };
export default reviewSlice.reducer;
