import React from 'react';
import { CiClock2 } from 'react-icons/ci';
import { AiOutlineMail,AiOutlineRight,AiOutlineLock } from 'react-icons/ai';
import { BsShopWindow , BsUnlock } from 'react-icons/bs';
import { VscBell } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

function YourBusiness() {
  const companyName = useSelector((state) => state.company.companyName);
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col justify-start items-start p-7 pl-11">
      <div className="w-[83%]"> 
        <div className="text-[24px] medium text-[#1A202C] border-b pb-4 tracking-wide">
          Your Business
        </div>
  
        <div>
          <div className="flex my-6 justify-between">
            <div className="flex gap-4 ">
              <div
                style={{
                  backgroundColor: '#e5e9edbf',
                }} 
                className="h-20 w-20 rounded-[17px]  flex items-center justify-center cursor-pointer "
              >
                {' '}

                <BsShopWindow className="!text-[#4c535d] !text-[34px] " />
                {' '}
              </div>
              <div>
                <div className="text-[24px] medium text-[#1A202C] ">
                  {companyName}
                </div>
               
              </div>
            </div>
            <div>
              {/* <button
            type="button"
            className="flex justify-center bg-white text-[#12151A] 
            shadow-md shadow-gray-200 focus:ring-4 gap-1  border border-gray-300
            medium text-[13px] rounded-[10px] focus:!ring-blue-200 
            focus:!border-blue-500  focus:border-[1px] py-1 px-3"
          >
            Update photo
          </button> */}

            </div>
          </div>
          <div onClick={() => navigate('business-hours')} className="flex justify-between items-center cursor-pointer mt-12 hover:bg-[#F7FAFC] p-3 rounded-xl">
            <div className="flex items-center gap-4">
              <CiClock2 className="text-[20px] text-[#555C67]" />
              <div className="flex flex-col">
                <span className="text-[#292E36] medium text-[14px]">Business Hours</span>
                <span className="text-[14px] text-[#555C67]">Set the days and hours your business is open.</span>
              </div>
            </div>
            <AiOutlineRight className="text-[18px] text-[#555C67]" />
          </div>
          <div className="flex justify-between items-center mt-4  p-3 rounded-2xl" />        
        </div>
      </div>
    </div>
  );
}

export default YourBusiness;
