/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import inboxApi from '../Apis/Inbox';

const initialState = {
  // For messages
  loading: false,
  error: null,
  messagesByContactId: {}, // Object to store messages by contact ID
  messagesLoadedByContactId: {},
  currentPageByContactId: {},
  totalPagesByContactId: {},

  // For conversation list
  data: [],
  filterData: [], 
  isFilter: false,
  isLoading: false,
  isError: false,
  totalContacts: 0,
  filteredContacts: 0,
  convpage: 0,
  totalPages: 1,
  filteredTotalPages: 1,
  search: '', 
  selectedConversation: null,
};

export const getAllConversationList = createAsyncThunk(
  'data/getAllConversationList', // Action type
  async ({
    page, search, isOpen, assignedTo, 
  }, thunkAPI) => {
    const response = await inboxApi.getAllConversationList(page,search, isOpen, assignedTo);
    return response.data;
  },
);

export const sendWAMsg = createAsyncThunk('inbox/sendWAMsg', async (value) => {
  const response = await inboxApi.sendWaMessage(value.data, value.contactId);
  return response.data; 
});

export const sendWebchatMsg = createAsyncThunk('inbox/sendWebchatMsg', async (value) => {
  const response = await inboxApi.sendWebchatMessage(value.data, value.contactId);
  return response.data; 
});

export const replyToMessage = createAsyncThunk('inbox/replyToMessage', async (value) => {
  const response = await inboxApi.replyToMessage(value.data, value.contactId);
  return response.data; 
});

export const getAllMessages = createAsyncThunk('inbox/getAllMessages', async (value) => {
  const response = await inboxApi.getAllMessages(value.page,value.contactId,value.userId, value.displayName);
  return response.data; 
});

export const getMessageByWamid = createAsyncThunk('inbox/getMessageByWamid', async (value) => {
  const response = await inboxApi.getMessageByWamid(value);
  return response.data; 
});

export const updateConversation = createAsyncThunk('inbox/updateConversation', async (data) => {
  const response = await inboxApi.updateConversation(data);
  return response.data; 
});

export const assignUser = createAsyncThunk('inbox/assignUser', async (data) => {
  const response = await inboxApi.assignUser(data);
  return response.data; 
});

export const getConversationAnalytics = createAsyncThunk('inbox/getConversationAnalytics', async (id) => {
  const response = await inboxApi.getConversationAnalytics(id);
  return response.data; 
});

const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    updateMessageStatus: (state, action) => {
      const { contact_id } = action.payload;
    
      // Retrieve the existing messages for the current contact, or create an empty array if none exists
      const messagesForContact = state.messagesByContactId[contact_id] || [];
    
      // Check if the message with the given ID is already present
      const existingMessage = messagesForContact.find((msg) => msg._id === action.payload._id);
    
      if (existingMessage) {
        // If the message is already present, update its status
        state.messagesByContactId = {
          ...state.messagesByContactId,
          [contact_id]: messagesForContact.map((msg) => {
            if (msg.wamid === action.payload.wamid) {
              // Return a modified message with the updated status
              return {
                ...action.payload,
              };
            }
            return msg; // Return unchanged messages
          }),
        };
      } else {
        // If the message is not present, push it to the array and update the status
        state.messagesByContactId = {
          ...state.messagesByContactId,
          [contact_id]: [action.payload, ...messagesForContact],
        };
      }
    },
    
    receivedMessage: (state, action) => {
      // Update the overall messageData
      // state.messageData = [action.payload, ...state.messageData];
    
      const { contact_id } = action.payload;
    
      // Retrieve the existing messages for the current contact, or create an empty array if none exists
      const messagesForContact = state.messagesByContactId[contact_id] || [];
    
      // Append the new message to the messages array for the current contact
      state.messagesByContactId = {
        ...state.messagesByContactId,
        [contact_id]: [action.payload, ...messagesForContact],
      };
    
      // Mark the messages as loaded for this contact
      state.messagesLoadedByContactId = {
        ...state.messagesLoadedByContactId,
        [contact_id]: true,
      };
    },

    clearMessages: (state,action) => {
      // console.log('action.payload',action.payload);
      state.messageData = action.payload;
    },
    assignedToContact: (state,action) => {
      state.data = [action.payload, ...state.data];
    },
    unassignedToContact: (state,action) => {
      // state.data = [action.payload, ...state.data];
      state.data = state.data.filter((contact) => contact._id !== action.payload._id);
    },
    mapUpdatedContactsWithAssignedTo: (state,action) => {
      state.data = state.data.map((e) => {
        if (e._id === action.payload._id) {
          return {
            ...e,
            assistedByUserId: action.payload.assistedByUserId,
            assistedByUserName: action.payload.assistedByUserName,
          };
        } 
        return e;
      });
    },
    updateConversationTOCloseOrOpen: (state,action) => {
      state.data = state.data.filter((contact) => contact._id !== action.payload._id);
    },
    addUnknowConversationToList: (state,action) => {
      state.data = [action.payload, ...state.data];
    },
    updateLastMessageInConv: (state,action) => {
      const newMessageContact = action.payload;

      // Find the index of the contact with the new message in the array
      const index = state.data.findIndex((contact) => contact._id === newMessageContact._id);

      if (index !== -1) {
        // If the contact is found, update it
        state.data[index] = newMessageContact;
        if (index !== 0) {
          // If the contact is not at the top, remove it from its current position and move it to the top
          state.data.splice(index, 1);
          state.data.unshift(newMessageContact);
        }
      } else {
        // If the contact is not found, add it to the top of the array
        state.data.unshift(newMessageContact);
      }
    },
    setConvSearchData: (state, action) => {
      state.filterData = action.payload;
      state.isFilter = false;
      state.filteredTotalPages = 1;
    },
    closedConversation: (state, action) => {
      state.data = action.payload;
      // state.messageData = action.payload;
      // state.page = 1;
      // state.messageTotalPages = 1;
      state.convpage = 1;
      state.totalPages = 1;
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(sendWAMsg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      
      .addCase(sendWAMsg.fulfilled, (state, action) => {
        state.loading = false;
        console.log('action.meta.arg', action.meta.arg);
        const { data, contactId } = action.meta.arg;
      
        if (data.type === 'reaction') {
          // console.log('');
        } else {
          // Retrieve the existing messages for the current contact, or create an empty array if none exists
          const messagesForContact = state.messagesByContactId[contactId] || [];
      
          // Prepend the new message to the array
          state.messagesByContactId = {
            ...state.messagesByContactId,
            [contactId]: [action.payload.data.data, ...messagesForContact],
          };
        }
      })
      .addCase(sendWAMsg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(sendWebchatMsg.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      
      .addCase(sendWebchatMsg.fulfilled, (state, action) => {
        state.loading = false;
        console.log('action.meta.arg>> here', action.meta.arg);
        const { data, contactId } = action.meta.arg;
        console.log('action.payload',action.payload);
        // Retrieve the existing messages for the current contact, or create an empty array if none exists
        const messagesForContact = state.messagesByContactId[contactId] || [];
      
        // Prepend the new message to the array
        state.messagesByContactId = {
          ...state.messagesByContactId,
          [contactId]: [action.payload.data, ...messagesForContact],
        };
      })
      .addCase(sendWebchatMsg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMessages.fulfilled, (state, action) => {
        const { contactId } = action.meta.arg;
      
        // Store the total pages for the current contact
        state.totalPagesByContactId = {
          ...state.totalPagesByContactId,
          [contactId]: action.payload.data.totalPages,
        };
      
        // Retrieve the existing messages for the current contact, or create an empty array if none exists
        const messagesForContact = state.messagesByContactId[contactId] || [];
      
        // Check if the new messages have already been received (e.g., by comparing message IDs)
        const newMessageIds = action.payload.data.data.map((message) => message._id);
        const existingMessageIds = messagesForContact.map((message) => message._id);
        const messagesToAdd = action.payload.data.data.filter(
          (message) => !existingMessageIds.includes(message._id),
        );
      
        // Append only the new messages to the messages array for the current contact
        messagesForContact.push(...messagesToAdd);
      
        // Update the messages for the contact in the state
        state.messagesByContactId = {
          ...state.messagesByContactId,
          [contactId]: messagesForContact,
        };
      
        // Update the current page for this contact
        state.currentPageByContactId = {
          ...state.currentPageByContactId,
          [contactId]: action.meta.arg.page,
        };
      
        // Mark the messages as loaded for this contact
        state.messagesLoadedByContactId = {
          ...state.messagesLoadedByContactId,
          [contactId]: true,
        };
      
        // Update the total pages for this contact (already done above)
      
        // Update other relevant state properties (e.g., loading, messageTotalPages, etc.)
        state.loading = false;
        state.page = action.meta.arg.page;
        state.messageTotalPages = action.payload.data.totalPages;
      })
      
      .addCase(getAllMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllConversationList.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAllConversationList.fulfilled, (state, action) => {
        const { page, search } = action.meta.arg;
        state.isLoading = false;
        state.isError = false;
        if (search) {
          console.log('is itin search???');
          state.isFilter = true;
          // If on page 1, replace the existing data; otherwise, append to it
          if (page === 1) {
            state.filterData = action.payload.data.contacts;
          } else {
            const newData = action.payload.data.contacts;
            const existingDataIds = new Set(state.data.map((contact) => contact._id));

            // Filter out duplicates by checking if the contact ID already exists
            const uniqueData = newData.filter((contact) => !existingDataIds.has(contact._id));

            state.filterData = [...state.data, ...uniqueData];
            // state.filterData = [...state.filterData, ...action.payload.data.contacts];
          }
          state.filteredContacts = action.payload.data.totalContacts;
          state.filteredTotalPages = action.payload.data.totalPages;
        } else {
          state.isFilter = false;
          // state.filterData = [];
          console.log('is itin page???');
          const newData = action.payload.data.contacts;
          const existingDataIds = new Set(state.data.map((contact) => contact._id));

          // Filter out duplicates by checking if the contact ID already exists
          const uniqueData = newData.filter((contact) => !existingDataIds.has(contact._id));

          state.data = [...state.data, ...uniqueData];
          state.convpage = page;
          state.totalContacts = action.payload.data.totalContacts;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(getAllConversationList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateConversation.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      // .addCase(updateConversation.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   const updatedConversations = action.payload.data;
      
    //   // Iterate over the array of updated conversations
    //   updatedConversations.forEach((updatedConversation) => {
    //     // Find the index of the contact with the updatedConversation in the array
    //     const index = state.data.findIndex((contact) => contact._id === updatedConversation._id);
      
      //     if (index !== -1) {
      //       // If the contact is found, remove it from the array using splice
      //       state.data.splice(index, 1);
      //     }
      //   });
      // })
      .addCase(updateConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedConversations = action.payload.data;
      
        // Create a new array without the contacts that were updated
        // state.data = state.data.filter((contact) => !updatedConversations.some((e) => e.updatedConversation._id === contact._id));
      })            
      .addCase(updateConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  updateMessageStatus, 
  assignedToContact,
  unassignedToContact,
  mapUpdatedContactsWithAssignedTo,
  receivedMessage, 
  clearMessages, 
  addUnknowConversationToList,
  setConvSearchData, 
  closedConversation,
  updateLastMessageInConv,
  updateConversationTOCloseOrOpen,
} = inboxSlice.actions;

// export { fetchAllReviews };
export default inboxSlice.reducer;
