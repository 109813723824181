import React from 'react';
import Button from '../../../ReusableComponents/Button';

function WebchatScript() {
  return (
    <div className="bg-[#fafbfd] w-full h-full flex justify-center items-center">
      <div className="w-[80%] border min-h-[300px] rounded-lg bg-white box-shadow p-10 flex flex-col justify-between">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1">
            <span className="text-[19px] medium text-[#1A202c]">Install CDN code</span>
            <span className="text-[#7b8994] text-sm">
              Install this script code before
              {' '}
              {/* <code className="text-sm"> */}
              {'</body>'}
              {' '}
              {/* </code> */}
              {' '}
              tag in your website .
            </span>
          </div>
          <div className="min-w-[110px]">
            <Button blue buttonText="Copy script" />
          </div>
        </div>
        <div className="bg-gray-50 h-[100px] rounded shadow-sm border p-4">{'<script defer="" type="text/javascript" src="https://dev.elyzian.in/api/v1/user/webchat/2e794f3d-0ed0-4601-b3f5-41c11aa7cbac"></script><div id="elyzian-webchat"></div>'}</div>
      </div>
    </div>
  );
}

export default WebchatScript;
