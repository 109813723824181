import React, { useState } from 'react';
import CustomSwitch from '../../ReusableComponents/CustomSwitch';
import PermissionList from './PermissionList';

const PermissionsComponent = ({ role,permissions,setPermissions }) => {
  console.log('permissions',permissions);

  // Function to toggle a specific permission
  const togglePermission = (category, permissionName) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [category]: {
        ...prevPermissions[category],
        [permissionName]: !prevPermissions[category][permissionName],
      },
    }));
  };

  return (
    <div className="border rounded">
      {/* Administrative Permissions */}
      {role !== 'TEAM_MEMBER' && (     
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Administrative</div>

        <PermissionList 
          value={permissions.administrative.manageSettings}
          setValue={(value) => togglePermission('administrative', 'manageSettings', value)}
          label="Manage Settings"
        />

        <PermissionList 
          value={permissions.administrative.manageUsers}
          setValue={(value) => togglePermission('administrative', 'manageUsers', value)}
          label="Manage Users"
        />

        <PermissionList 
          value={permissions.administrative.viewRolePermissions}
          setValue={(value) => togglePermission('administrative', 'viewRolePermissions', value)}
          label="View Role Permissions"
        />

        <PermissionList 
          value={permissions.administrative.editRolePermissions}
          setValue={(value) => togglePermission('administrative', 'editRolePermissions', value)}
          label="Edit Role Permissions"
        />
      </div>
      )}

      {/* Messaging Permissions */}
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Messaging</div>
        <PermissionList 
          value={permissions.messenger.createNewConversations}
          setValue={(value) => togglePermission('messenger', 'createNewConversations', value)}
          label="Create New Conversations"
        />

        <PermissionList 
          value={permissions.messenger.myConversations}
          setValue={(value) => togglePermission('messenger', 'myConversations', value)}
          label="My Conversations"
        />

        <PermissionList 
          value={permissions.messenger.conversationsAssignedToOthers}
          setValue={(value) => togglePermission('messenger', 'conversationsAssignedToOthers', value)}
          label="Conversations Assigned To Others"
        />

        <PermissionList 
          value={permissions.messenger.unassignedConversations}
          setValue={(value) => togglePermission('messenger', 'unassignedConversations', value)}
          label="Unassigned Conversations"
        />

        <PermissionList 
          value={permissions.messenger.manageTemplates}
          setValue={(value) => togglePermission('messenger', 'manageTemplates', value)}
          label="Manage Templates"
        />

        <PermissionList 
          value={permissions.messenger.deleteMessage}
          setValue={(value) => togglePermission('messenger', 'deleteMessage', value)}
          label="Delete Message"
        />
      </div>

      {/* Reviews Permissions */}
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Reviews</div>
        <PermissionList 
          value={permissions.reviews.viewReviews}
          setValue={(value) => togglePermission('reviews', 'viewReviews', value)}
          label="View Reviews"
        />

        <PermissionList 
          value={permissions.reviews.respondToReviews}
          setValue={(value) => togglePermission('reviews', 'respondToReviews', value)}
          label="Respond to Reviews"
        />
      </div>

      {/* Reports Permissions */}
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Reports</div>
        <PermissionList 
          value={permissions.reporting.basicReports}
          setValue={(value) => togglePermission('reporting', 'basicReports', value)}
          label="Basic Reports"
        />

        <PermissionList 
          value={permissions.reporting.exportReports}
          setValue={(value) => togglePermission('reporting', 'exportReports', value)}
          label="Export Reports"
        />
      </div>

      {/* Webchat Permissions */}
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Webchat</div>
        <PermissionList 
          value={permissions.webchat.viewWebchatSettings}
          setValue={(value) => togglePermission('webchat', 'viewWebchatSettings', value)}
          label="View Webchat Settings"
        />

        <PermissionList 
          value={permissions.webchat.appearanceAndAutoResponder}
          setValue={(value) => togglePermission('webchat', 'appearanceAndAutoResponder', value)}
          label="Appearance and Auto-Responder"
        />

        <PermissionList 
          value={permissions.webchat.locationPicker}
          setValue={(value) => togglePermission('webchat', 'locationPicker', value)}
          label="Location Picker"
        />

        <PermissionList 
          value={permissions.webchat.onlineForms}
          setValue={(value) => togglePermission('webchat', 'onlineForms', value)}
          label="Online Forms"
        />

        <PermissionList 
          value={permissions.webchat.hostedForms}
          setValue={(value) => togglePermission('webchat', 'hostedForms', value)}
          label="Hosted Forms"
        />
      </div>

      {/* Automation Permissions */}
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Automations</div>
        <PermissionList 
          value={permissions.automations.viewAutomations}
          setValue={(value) => togglePermission('automations', 'viewAutomations', value)}
          label="View automations"
        />

        <PermissionList 
          value={permissions.automations.manageAutomations}
          setValue={(value) => togglePermission('automations', 'manageAutomations', value)}
          label="Manage Automations"
        />

        <PermissionList 
          value={permissions.automations.manageUserMappings}
          setValue={(value) => togglePermission('automations', 'manageUserMappings', value)}
          label="Manage User Mappings"
        />
      </div>

      {/* Campaign Permissions */}
      <div className="border-b p-5">
        <div className="semibold text-[#12151A] text-[17px]">Campaigns</div>
        <PermissionList 
          value={permissions.campaigns.viewCampaigns}
          setValue={(value) => togglePermission('campaigns', 'viewCampaigns', value)}
          label="View campaigns"
        />

        <PermissionList 
          value={permissions.campaigns.createNewCampaigns}
          setValue={(value) => togglePermission('campaigns', 'createNewCampaigns', value)}
          label="Create New Campaigns"
        />

        <PermissionList 
          value={permissions.campaigns.sendCampaigns}
          setValue={(value) => togglePermission('campaigns', 'sendCampaigns', value)}
          label="Send Campaigns"
        />

        <PermissionList 
          value={permissions.campaigns.exportMarketingList}
          setValue={(value) => togglePermission('campaigns', 'exportMarketingList', value)}
          label="Export Marketing List"
        />

        <PermissionList 
          value={permissions.campaigns.marketingAutomationsAccess}
          setValue={(value) => togglePermission('campaigns', 'marketingAutomationsAccess', value)}
          label="Marketing Automations Access"
        />
      </div>
    </div>
  );
};

export default PermissionsComponent;
