/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
  GrEmoji,GrCode,GrStrikeThrough,GrItalic, GrBold,GrFormAdd,
} from 'react-icons/gr';
import Input from '../../ReusableComponents/Input';

const CustomTextEditor = ({
  bodyText,
  setBodyText,
  variables,
  setVariables,
  variableContent,
  setVariableContent,
  variableCounter,
  setVariableCounter,
  Language,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleAddVariable = () => {
    const newbody = `${bodyText} {{${variableCounter}}}`;
    setBodyText(newbody);
    setVariables([...variables, variableCounter]);
    setVariableContent([...variableContent, '']);
    setVariableCounter(variableCounter + 1);
  };

  const handleVariableContentChange = (index, value) => {
    const updatedVariableContent = [...variableContent];
    updatedVariableContent[index] = value;
    setVariableContent(updatedVariableContent);
  };

  const applyFormatting = (format) => {
    const selectedText = window.getSelection().toString().trim();

    if (selectedText) {
      let formattedText = selectedText;

      switch (format) {
        case 'bold':
          formattedText = `*${selectedText}*`;
          break;
        case 'italic':
          formattedText = `_${selectedText}_`;
          break;
        case 'strikethrough':
          formattedText = `~${selectedText}~`;
          break;
        case 'monospace':
          formattedText = `\`\`\`${selectedText}\`\`\``;
          break;
        default:
          break;
      }

      const updatedbody = bodyText.replace(selectedText, formattedText);
      setBodyText(updatedbody);
    }
  };

  const handleEmojiSelect = (e) => {
    // const emojiToAdd = `:${emoji}:`;
    const emoji = e.native;
    setBodyText(bodyText + emoji);
    setShowEmojiPicker(false);
  };

  const handleBodyTextChange = (e) => {
    const newText = e.target.value;
    setBodyText(newText);
  
    // Check for the presence of '{{}}' in the body text
    // if (!newText.includes('{{}}')) {
    // // If '{{}}' is not found, remove only the last variable and its content
    //   setVariables(variables.slice(0, -1));
    //   setVariableContent(variableContent.slice(0, -1));

    //   // Increment the variable counter to account for the removed variable
    //   setVariableCounter(variableCounter - 1);
    // }
  };

  return (
    <div>
      <div className="min-h-[180px]">
        <Input
          type="text"
          height="min-h-[180px]"
          value={bodyText}
          limit
          setLimit="1024"
          onChange={handleBodyTextChange}
        // setValue={setBodyText}
          placeholder={`Enter text in ${Language}`}
        />
      </div>
      <div className="flex justify-end my-2 gap-3 text-[#1c2b33d3] relative">
        <button type="button" className="hover:bg-gray-100 rounded-md hover:shadow-sm p-[3px]" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
          <GrEmoji />
        </button>
       
        <button type="button" className="hover:bg-gray-100 rounded-md hover:shadow-sm p-[3px]" onClick={() => applyFormatting('bold')}>
          <GrBold />
        </button>
        <button type="button" className="hover:bg-gray-100 rounded-md hover:shadow-sm p-[3px]" onClick={() => applyFormatting('italic')}>
          <GrItalic />
        </button>
        <button type="button" className="hover:bg-gray-100 rounded-md hover:shadow-sm p-[3px]" onClick={() => applyFormatting('strikethrough')}>
          <GrStrikeThrough />
        </button>
        <button type="button" className="hover:bg-gray-100 rounded-md hover:shadow-sm p-[3px]" onClick={() => applyFormatting('monospace')}>
          <GrCode />
        </button>
        <button type="button" className="hover:bg-gray-100 rounded-md hover:shadow-sm p-[3px] px-2 flex items-center text-sm" onClick={handleAddVariable}>
          <GrFormAdd />
          {' '}
          Add Variable
        </button>

        {showEmojiPicker && (
        <div className="absolute bottom-8">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div>
        )}
      </div>
      {variables?.length > 0 && (
      <div className="border p-3 rounded-lg bg-[#00000008] ring-[#b0c6ff] ring-[1px] space-y-3">
        <div className="flex flex-col gap-2">
          <span className="text-sm text-gray-600 medium">Variable for Body Content.</span>
     
          <span className="text-[#1c2b33a6] text-[13px]">Choose a type of variable to include in this body. You can assign  variables to this body.</span>
        </div>
        {variables?.map((variable, index) => (
          <div key={variable} className="w-full flex gap-1 items-center">
            <span className="w-[7%] text-[] text-sm">{`{{${variable}}}`}</span>
            <input
              type="text"
              className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
              placeholder={`Enter Content for {{${variable}}}`}
              value={variableContent[index] || ''}
              onChange={(e) => handleVariableContentChange(index, e.target.value)}
            />
          </div>
        ))}
     
      </div>
      )}
    </div>
  );
};

export default CustomTextEditor;
