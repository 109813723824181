import React from 'react';

function PreviewBackground() {
  return (
    <div className="w-full h-full bg-[#f1f5fb]">
      <div className="h-10 bg-[#c8cfd9] flex justify-center">
        <span className="w-2/3 bg-[#fafbfd] rounded-3xl" />
      </div>
      <div className="p-10 h-[calc(100%-40px)]">
        <div className="flex justify-between">
          <span className="h-[65px] bg-[#e0e6ee] w-[40%]  rounded-lg  box-shadow" />
          <span className="h-[65px] bg-[#e0e6ee] w-[65px] border rounded-full box-shadow" />

        </div>
        <div className="h-[40%] mt-8 bg-[#e0e6ee] w-full  rounded-lg  box-shadow" />
        <div className="flex mt-5 gap-4 h-[38%] 2xl:h-[40%]">
          <div className="h-full  bg-[#e0e6ee] w-full  rounded-lg  box-shadow" />
          <div className="h-full  bg-[#e0e6ee] w-full  rounded-lg  box-shadow" />

        </div>
      </div>
    </div>
  );
}

export default PreviewBackground;
