/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useState } from 'react';
import { AiOutlineSearch,AiOutlineCloseCircle } from 'react-icons/ai';

function Input({
  value,onChange,placeholder,height,rounded,type, limit,setLimit,
}) {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);

  const handleResizing = (e) => {
    const input = inputRef?.current;
    // console.log(input,'=====');
    if (input !== null) {
      input.style.height = '100%';
      input.style.height = `${input.scrollHeight}px`;
    }
  };
  function handleTextareaHeight(event) {
    event.target.style.height = '100%';
    event.target.style.height = `${event.target.scrollHeight}px`;
  }
  
  return (
    <div style={{ borderRadius: rounded || '8px' }} className={`${focus ? `${value?.length < setLimit ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff]' : 'border border-[#e73e51] ring-[3px] transition-all  ring-red-100 '} ` : 'hover:border-[#9EA9B8]'} w-full  shadow-sm border  ${height || 'min-h-8 '} px-2  flex bg-white`}>
      <textarea
        placeholder={placeholder}  
        value={value}      
        onChange={onChange} 
        ref={inputRef}
        onBlur={() => setFocus(false)} 
        onFocus={() => setFocus(true)} 
        onInput={handleTextareaHeight}
        // style={{ height }}
        className={`resize-none text-[14.4px] overflow-hidden 
                 
          text-gray-900 text-sm  outline-none ${height || 'h-full'}
           w-[100%] 
            p-1.5 py-[10px] placeholder:text-[13.5px] placeholder:tracking-wide`}
        // className="w-[100%] flex placeholder:text-[13.5px] resize-y h-full   outline-none text-[14.4px]"
        type={type}
      />
      {/* <span className="w-[10%] text-gray-600 flex justify-center items-center">
        {value?.length > 0
       && <button onClick={() => setValue('')} type="button"><AiOutlineCloseCircle /></button>}

      </span> */}
      {limit && (
      <span className="text-[0.7rem] pt-[10px] text-[#1c2b33a6]">
        {value?.length}
        /
        {setLimit}
      </span>
      )}
    </div>
  );
}

export default Input;
