import React from 'react';
import { MdOutlineDriveFileRenameOutline,MdEmail,MdPhone } from 'react-icons/md';

const AppointmentList = ({ e }) => (
  <div className="flex w-full justify-between bg-white border shadow-md rounded-xl py-6 px-5 min-h-[100px] ">
    <div className="w-[70%]">
      <div className="flex items-center gap-10 w-full">
        <div className="flex items-center gap-2 w-[20%] text-ellipsis">
              
          <span className="medium text-lg text-ellipsis">
            {e.name.length > 14 ? `${e.name.slice(0,10)}...` : e.name}
                     
          </span>
        </div>
        <div className=" text-sm flex items-center gap-2 ">
          <span className="flex items-center gap-1  ">  
            {' '}
            <MdEmail className="text-yellow-500 text-base" />
 
            Email:
          </span>
 
          <span className=" text-sm medium text-gray-700"> 
            {' '}
            {e.email}
          </span>
        </div>
        <div className=" text-sm flex items-center gap-2">
          <span className="flex items-center gap-1  ">  
            {' '}
            <MdPhone className="text-green-500 text-base" />
 
            Phone:
          </span>
 
          <span className=" text-sm medium text-gray-700"> 
            {' '}
            {e.phone}
          </span>
        </div>
      </div>
      <div className="mt-4 flex items-center gap-10">        

        <span className=" flex items-center gap-1 text-sm text-gray-500 ">
          Appointment Date:
          <span className="medium text-blue-700">
            {e.date}
          </span>
        </span>
        <span className=" flex items-center gap-1 text-sm text-gray-500">
          Appointment Time:
          <span className="medium text-blue-700">
            {e.time}
          </span>
        </span>
      </div>
    </div>
    <div>
      <div className="text-sm flex gap-4 items-center">
        <span className=" text-sm text-gray-500">Status:</span>
        <span className="bg-green-600 tracking-wide rounded-full border-green-800 px-3 py-[2px] text-xs  text-white">{e.status}</span>
      </div>
    </div>
  </div>
);

export default AppointmentList;
