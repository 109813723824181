import React, { useEffect, useRef, useState } from 'react';
import { RiDeleteBinLine,RiUserFill } from 'react-icons/ri';
import { HiDotsVertical } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { BsChevronDown } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';
import { getColorForAlphabet } from '../../Common/Colors';
import SimpleModal from '../../ReusableComponents/SimpleModal';

const menuOptions = [
  { label: 'Owner ', value: 'Owner' },
  { label: 'Administrator', value: 'administrator' },
  { label: 'User', value: 'User' },
  // { label: 'System', value: 'System' },
];
  
const UserList = ({
  data, accessLevel,setUserEdited,
}) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState(data.accessLevel);
  const [editUser, setEditUser] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rolesModal, setRolesModal] = useState(false);

  const regex = /^[^a-zA-Z]/;
  // console.log('user data',data);
  return (
    <div className="flex hover:bg-[#fafbfd] cursor-pointer rounded-xl items-center justify-around h-14">
      <div className="flex space-x-4 items-center text-center w-[31%] justify-between">
        <div className="w-[40px]">
          <div
            style={{
              backgroundColor: regex.test((data.displayName || data.email)?.slice(0, 1)?.toUpperCase()) ? '#D2FAF1' : getColorForAlphabet((data.displayName || data.email)?.slice(0, 1)?.toUpperCase()),
            }}
            className="h-8 w-8 rounded-[8px] relative  flex items-center justify-center cursor-pointer "
          >
            <span className="text-white inbox-message-regular text-[16px]">
              {regex.test((data.displayName || data.email)?.slice(0, 1)?.toUpperCase())
                ? <RiUserFill className="!text-[#2EB597] !text-[24px] mt-1" /> 
                : (data.displayName || data.email)?.slice(0, 1)?.toUpperCase()}
            </span>
          </div>
        </div>
        <div className="flex flex-col text-[#555c67] w-[calc(100%-40px)] items-start">
          <span
            className="medium text-sm cursor-pointer  "
          >
            {/* {console.log(cell)} */}
            {data.displayName?.length > 30 ? `${data.displayName.slice(0, 20)}...` : data.displayName}
          </span>
          <span
            className="text-xs cursor-pointer"
          >
            {data.email}             
          </span> 
        </div>

      </div> 
      {/* <div className="w-[23%] text-center">
        <span
          className="!text-[#212121] !p-0 cursor-pointer !text-[15px] !font-normal !font-sans"
        >
          {data.email}             
        </span>   
      </div> */}
      <div className="w-[15%] text-center">
        <span className="text-[#555c67]  cursor-pointer"> 
          {/* {userRole === 'Owner' ? ( */}
         
          <button
            type="button"
            onClick={() => setRolesModal(true)}
            className="hover:!ring-4 shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white          
                rounded-[16px] w-[80%] flex text-sm p-[5px] items-center justify-around
                hover:!ring-blue-200 hover:!border-blue-500 medium "
          >
            <span className="text-xs medium capitalize">{role === 'PRIMARY_OWNER' ? 'OWNER' : role === 'TEAM_MEMBER' ? 'TEAM MEMBER' : role}</span>
            <BsChevronDown className="text-xs" />
          </button>
         
          {/* ) : (
            <div>sdf</div>
          )} */}
          
          {/* {data.role} */}
        </span>         
      </div>
      <div className="w-[23%] text-center">
        <span className="text-xs cursor-pointer text-[#555c67]"> 
          {data.lastVisited ? new Date(data.lastVisited).toDateString() : 'User did not login yet'}
        </span>    
      </div>

      {/* <SimpleModal open={openEditUserModal} setOpen={setOpenEditUserModal} 
      setSelectValue="" modalWidth="643px">
        <EditUser setOpenEditUserModal={setOpenEditUserModal} data={data} />
      </SimpleModal>
      <SimpleModal open={rolesModal} setOpen={setRolesModal} setSelectValue="" modalWidth="643px">
        <EditRoles
          data={data}
          setUserEdited={setUserEdited} 
          setRolesModal={setRolesModal} 
          accessLevel={accessLevel}
        />
      </SimpleModal> */}
    </div>
  ); 
};

export default UserList;
