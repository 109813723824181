import React from 'react';
import CustomizeBody from './CustomizeBody';
import PreviewBackground from './PreviewBackground';
import Body from './Body';

function WebchatBody({
  headerText, 
  setHeaderText,
  headerSubText,
  setHeaderSubText,
  headerColor,
  setHeaderColor,
  buttonColor, 
  setButtonColor,
}) {
  return (
    <div className="w-full h-full flex">
      <div className="w-[45%] bg-[#fafbfd]">
        <CustomizeBody
          headerText={headerText} 
          setHeaderText={setHeaderText}
          headerSubText={headerSubText} 
          setHeaderSubText={setHeaderSubText}
          headerColor={headerColor} 
          setHeaderColor={setHeaderColor}
          buttonColor={buttonColor} 
          setButtonColor={setButtonColor} 
        />
      </div>
      <div className="w-[55%] h-full relative ">
        <PreviewBackground />
        <Body
          headerText={headerText} 
          setHeaderText={setHeaderText}
          headerSubText={headerSubText} 
          setHeaderSubText={setHeaderSubText}
          headerColor={headerColor} 
          setHeaderColor={setHeaderColor}
          buttonColor={buttonColor} 
          setButtonColor={setButtonColor} 
        />
      </div>
    </div>
  );
}

export default WebchatBody;
