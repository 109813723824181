import './App.css';
import React, {
  lazy, Suspense, useEffect, useState, 
} from 'react';
import {
  Routes, Route, Navigate, useNavigate, 
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import AppRoutes from './AppRoutes';
import { fetchCompanyById } from './slice/companySlice';
import { fetchAllUsers, fetchUserById } from './slice/userSlice';
import { setOnlineStatus } from './slice/onlineSlice';
import Navbar from './components/NavBar/Navbar';
import { fetchAllTemplates } from './slice/templateSlice';
import axios from './Apis/axiosConfig';

// import Login from './components/Login/Login';

// Lazy-loaded route components
const Login = lazy(() => import('./components/Login/Login'));
const LoginCallback = lazy(() => import('./components/Login/LoginCallback'));

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.users.userData.data);
  const company = useSelector((state) => state.company.data);
  const isOnline = useSelector((state) => state.online.isOnline);
  const [loginSuccess, setloginSuccess] = useState('');

  const [user, setUser] = useState(localStorage.getItem('userId'));
  // const [user, setUser] = useState('651538debf46711966313148');
  // const [companyId, setCompanyId] = useState('650c64be035ef7dd6769dbc0');

  const [companyId, setCompanyId] = useState('');
  // console.log('isOnline',isOnline);
  console.log('company',company, userData,user);

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setOnlineStatus(true));
    };

    const handleOffline = () => {
      dispatch(setOnlineStatus(false));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      fetchUser(user);
      handleGetTemplates();
      dispatch(fetchAllUsers());
      // window.location.href = '/inbox';

      // navigate('/inbox');
    }
  },[user]);
  // useEffect(() => {
  //   if (user && company && userData) {
  //     console.log('Navigating to /');
  //     window.location.href = '/inbox';
  //   }
  // }, [user, company, userData]);

  const fetchCompany = async (id) => {
    const res = await dispatch(fetchCompanyById(id)).unwrap();
  };

  const fetchUser = async (id) => {
    const res = await dispatch(fetchUserById(id)).unwrap();
    if (res.status === 'success') {
      fetchCompany(res.data.company_id);
      setCompanyId(res.data.company_id);
    }
  };

  const handleGetTemplates = async () => {
    const res = await dispatch(fetchAllTemplates()).unwrap();
    console.log('handleGetTemplates res', res);
  };

  return (
    <div className="w-screen h-screen">
      {user ? (
        <>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <Navbar />
          <div className="sharedLayout">
            <AppRoutes userId={user} />
          </div>  
        </>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<Login navigate={navigate} userData={userData} />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="/*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      )}     

      {/* For testing      */}
      {/* <Suspense fallback={<div>Loading...</div>}>
        <>
          <Navbar />
          <div className="sharedLayout">
            <AppRoutes userId={user} />
          </div>  
        </>
      </Suspense> */}
    </div>
  );
}

export default App;
