// onlineSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOnline: navigator.onLine,
};

const onlineSlice = createSlice({
  name: 'online',
  initialState,
  reducers: {
    setOnlineStatus: (state, action) => {
      state.isOnline = action.payload;
    },
  },
});

export const { setOnlineStatus } = onlineSlice.actions;
export default onlineSlice.reducer;
