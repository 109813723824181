/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { AiOutlineSearch,AiOutlineCloseCircle } from 'react-icons/ai';

function CustomInput({
  value,setValue,placeholder ,height,rounded, icon,
}) {
  const [focus, setFocus] = useState(false);
  return (
    <div style={{ borderRadius: rounded || '8px' }} className={`${focus ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff] ' : 'hover:border-[#9EA9B8]'} w-full  shadow-sm border ${icon ? '' : 'pl-3'}  ${height || 'h-8'} flex items-center px-2 `}>
      {icon && <span className="w-[10%] text-lg text-gray-400 flex justify-center items-center"><AiOutlineSearch /></span>}
      <input
        placeholder={placeholder}  
        value={value}      
        onChange={(e) => setValue(e.target.value)} 
        onBlur={() => setFocus(false)} 
        onFocus={() => setFocus(true)} 
        className={`${icon ? 'w-[80%]' : 'w-[90%]'} placeholder:text-[13.5px]  h-full outline-none text-[14.4px]`}
        type="text"
      />
      <span className="w-[10%] text-gray-600 flex justify-center items-center">
        {value?.length > 0
       && <button onClick={() => setValue('')} type="button"><AiOutlineCloseCircle /></button>}

      </span>
    </div>
  );
}

export default CustomInput;
