/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { MdCampaign } from 'react-icons/md';
import { AiFillBell } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { FaKey } from 'react-icons/fa6';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, 
} from '@mui/material';
import Button from '../../ReusableComponents/Button';
import { boxShadowStyle } from '../../Common/Colors';
import SimpleModal from '../../ReusableComponents/SimpleModal';
import LearnAboutTemplate from './LearnAboutTemplate';
import CustomInput from '../../ReusableComponents/CustomInput';
import SelectOptions from '../../ReusableComponents/SelectOptions';

const languageOptions = [
  { label: 'English', value: 'en' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Telugu', value: 'te' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Kannada', value: 'kn' },
  // { label: 'Odia', value: 'or' },
  // { label: 'Punjabi', value: 'pa' },
  // { label: 'Malayalam', value: 'ml' },
  // { label: 'Assamese', value: 'as' },
  // { label: 'Maithili', value: 'mai' },
  // { label: 'Sanskrit', value: 'sa' },
  // { label: 'Kashmiri', value: 'ks' },
  // { label: 'Sindhi', value: 'sd' },
  // { label: 'Manipuri', value: 'mni' },
  // { label: 'Konkani', value: 'kok' },
  // { label: 'Nepali', value: 'ne' },
  // { label: 'Bodo', value: 'brx' },
];

const CTStep1 = ({ 
  step, setStep,setCategory,setName,Name ,setLanguage,Language,Category,
}) => {
  const [learnAboutModal, setLearnAboutModal] = useState(false);
  const [marketingSelect, setMarketingSelect] = useState(false);
  const [value, setValue] = useState('Custom');
  const navigate = useNavigate();

  console.log('value',value);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  
  const handleCategory = (type) => {
    setCategory(type);
  };

  const closePopUp = (ref) => {
    useEffect(() => {     
      const handleClickOutside = (event) => {
        // console.log('ref event',event,ref);
        if (ref.current && !ref.current.contains(event.target)) {
          setMarketingSelect(false);
          // alert('clicked outside');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  closePopUp(wrapperRef);
  return (
    <div className="2xl:p-10 p-7 pt-10 w-full h-full  overflow-y-scroll">
      <div className="flex justify-between">
        <div className="text-2xl text-[#1A202c] medium">
          New Message Template

        </div>

        <div className="flex gap-3">
          <div className="min-w-[130px]"> 
            <Button onClick={() => navigate(-1)} buttonText="Cancel" />
          </div>
          <div className="min-w-[130px]"> 
            <Button disabled={Category === '' || Language === '' || Name === ''} buttonText="Continue" blue onClick={() => setStep(2)} />
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-10 gap-3">
        {/* #e1e9f5 */}
        <div style={boxShadowStyle} className="bg-white p-5 px-7 rounded-lg space-y-5">
          <div className="flex flex-col gap-[5px]">
            <span className="text-base text-[#1A202C] medium">Category</span>
            <span className="text-[.8rem] text-[#555C67] ">
              Choose a category that best describes your message template. 
              <button type="button" onClick={() => setLearnAboutModal(true)} className="text-blue-600 hover:cursor-pointer hover:text-blue-500">  Learn more about categories.</button>
            </span>
          </div>
          <div onClick={() => { setMarketingSelect(true); setCategory('MARKETING'); }} ref={wrapperRef} className={` ${Category === 'MARKETING' ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff]' : ''} flex flex-col px-4 py-4 rounded-lg shadow-sm bg-[#FAFBFD] gap-6 border cursor-pointer transition-all duration-200 ease-linear`}>
            <div className="flex items-center gap-6 ">

              <div className=" w-14 h-14 flex justify-center items-center text-[#2f3338] border p-2 bg-white rounded-full shadow-sm">
                <MdCampaign className="text-[1.8rem]" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-[#1c2b33] medium">Marketing</span>
                <span className="text-[#1c2b33] text-sm">Promotions or information about your business, products or services. Or any message that isn’t utility or authentication.</span>

              </div>
            </div>

            {marketingSelect && (
            <FormControl
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: 'GraphikRegular',
                  color: '#292E36',
                  fontSize: '14px', 
                },
           
              }}
              className="space-y-2 !pl-2"
            >
              {/* <label className="text-[0.84rem] medium text-[#292e36]">Review Sites</label> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={value}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <div className="flex flex-col mb-4">
                  <FormControlLabel value="Custom" control={<Radio />} label="Custom" />
                  <span className="text-xs ml-[30px]">Send promotional offers, announcements and more to increase awareness and engagement.</span>
                </div>
                <FormControlLabel value="Product Messages" control={<Radio />} label="Product Messages" />
                <span className="text-xs ml-[30px]">Send messages about your entire catalog or multiple products from it.</span>
                {/* <FormControlLabel value="other" control={<Radio />} label="Facebook" /> */}
              </RadioGroup>
            </FormControl>
            )}
          </div>
          <div onClick={() => handleCategory('UTILITY')} className={`${Category === 'UTILITY' ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff]' : ''} flex px-4 py-4 hover:cursor-pointer rounded-lg shadow-sm bg-[#FAFBFD] gap-6 border items-center `}>
            <div className="text-[1.5rem] w-14 h-14 flex justify-center items-center text-[#2f3338] border p-2 bg-white rounded-full shadow-sm">
              <AiFillBell />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#1c2b33] medium">Utility</span>
              <span className="text-[#1c2b33] text-sm">Messages about a specific transaction, account, order or customer request.</span>

            </div>
          </div>
          <div onClick={() => handleCategory('AUTHENTICATION')} className={` ${Category === 'AUTHENTICATION' ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff]' : ''} flex hover:cursor-pointer px-4 py-4 rounded-lg shadow-sm bg-[#FAFBFD] gap-6 border items-center `}>
            <div className="text-[1.4rem] flex w-14 h-14 justify-center items-center text-[#2f3338] border p-2  bg-white rounded-full shadow-sm">
              <FaKey className="" />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#1c2b33] medium">Authentication</span>
              <span className="text-[#1c2b33] text-sm">One-time passwords your customers use to authenticate a transaction or login.</span>

            </div>
          </div>
        </div>

        <div style={boxShadowStyle} className="bg-white p-5 px-7 rounded-lg flex flex-col gap-3">
          <div className="flex flex-col gap-[5px]">
            <span className="text-base text-[#1A202C] medium">Name</span>
            <span className="text-[.8rem] text-[#555C67] ">
              Name your message template.
              {' '}

            </span>
          </div>
          <div>
            <CustomInput setValue={setName} placeholder="Enter message template name..." height="h-10" />

          </div>
        </div>

        <div style={boxShadowStyle} className="bg-white p-5 px-7 rounded-lg flex flex-col gap-3">
          <div className="flex flex-col gap-[5px]">
            <span className="text-base text-[#1A202C] medium">Language</span>
            <span className="text-[.8rem] text-[#555C67] ">
              Choose languages for your message template. You can delete or add more languages later.              

            </span>
          </div>
          <div>
            <SelectOptions value={Language} setValue={setLanguage} menuOptions={languageOptions} label="Select Languages" size="small" />

          </div>
        </div>
      </div>
      <SimpleModal open={learnAboutModal} setOpen={setLearnAboutModal}>
        <LearnAboutTemplate setLearnAboutModal={setLearnAboutModal} />
      </SimpleModal>
    </div>
  ); 
};

export default CTStep1;
