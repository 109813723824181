import axios from 'axios';
import { baseURL,companyId, config } from '../slice/HEADERS';

const createTemplates = (payload) => axios.post(`${baseURL}/templates/create/${companyId}`,payload,config);

const getAllTemplates = () => axios.get(`${baseURL}/templates/get/${companyId}`,config);

const templatesApi = {
  createTemplates,
  getAllTemplates,
};
  
export default templatesApi;
