import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import companyReducer from './companySlice'; // Import your slice reducer
import onlineReducer from './onlineSlice';
import contactsReducer from './contactSlice';
import reviewsReducer from './reviewsSlice';
import templateReducer from './templateSlice';
import inboxReducer from './inboxSlice';
import tagsReducer from './tagsSlice';
import appointmentReducer from './appointmentSlice';

const rootReducer = combineReducers({
  users: userReducer,
  company: companyReducer,
  online: onlineReducer,
  contacts: contactsReducer,
  reviews: reviewsReducer,
  templates: templateReducer,
  inbox: inboxReducer,
  tags: tagsReducer,
  appointments: appointmentReducer,
});

const store = configureStore({
  reducer: rootReducer, // Include the combined reducers
  // Additional store configuration options
});

export default store;
