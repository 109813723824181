/* eslint-disable max-len */
import React, {
  useEffect, useLayoutEffect, useRef, useState, 
} from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import {
  Link, Outlet,useLocation,useNavigate, 
} from 'react-router-dom';
import { BiLogoTelegram } from 'react-icons/bi';
import { SiGoogleads } from 'react-icons/si';
import { HiChatBubbleOvalLeft } from 'react-icons/hi2';

const NavigationMenu = ({ options,setshowMarketing }) => {
  const closePopUp = (ref) => {
    useEffect(() => {     
      const handleClickOutside = (event) => {
        // console.log('ref event',event,ref);
        if (ref.current && !ref.current.contains(event.target)) {
          setshowMarketing(false);
          // alert('clicked outside');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  closePopUp(wrapperRef);
  return (
    <div ref={wrapperRef} className="absolute z-10 min-w-[270px] max-w-[332px] max-h-[250px] bg-[#1b1f26] text-white rounded-lg  top-[55px]">
      <div className="py-3  border-[#383e47]">
        { options?.map((e) => (
          <div
            key={e.id}
            onClick={() => {
              if (typeof e.onClick === 'function') {
                e.onClick();
              } else {
                navigate(e.onClick);
                setshowMarketing(false);
              }
            }}
            className="flex hover:bg-[#292e36] rounded-xl items-center gap-3 px-5 py-3 text-xs cursor-pointer"
          >
            <span className="text-base">{e.icon}</span>
            <span className="medium">{e.text}</span>
          </div>
        ))}

      </div>
    </div>

  ); 
};

function NavTabs() {
  const [showMarketing, setshowMarketing] = useState(false);
  const location = useLocation();

  const [activeCategory, setActiveCategory] = useState('');
  // console.log('active category',activeCategory);
  
  useEffect(() => {
    const path = location.pathname;
 
    // console.log('Current path:', path);
 
    if (path === '/') {
      setActiveCategory('home');
    } else if (path.startsWith('/inbox')) {
      setActiveCategory('inbox');
    } else if (path === '/contacts') {
      setActiveCategory('contacts');
    } else if (path.startsWith('/marketing')) {
      setActiveCategory('marketing');
    } else if (path.startsWith('/reviews')) {
      setActiveCategory('reviews');
    } else if (path.startsWith('/automations')) {
      setActiveCategory('automations');
    } else if (path.startsWith('/digital-showroom')) {
      setActiveCategory('digital-showroom');
    } else if (path.startsWith('/website-tools')) {
      setActiveCategory('website-tools');
    } else {
      // Set a default category or handle additional categories as needed
      setActiveCategory('');
    }
  }, [location.pathname]);

  const MarketingOptions = [
    {
      id: 1,
      icon: <BiLogoTelegram />,
      text: 'Campaigns',
      onClick: '/marketing/all-campaigns',
    },
    {
      id: 2,
      icon: <HiChatBubbleOvalLeft />,
      text: 'Website Tools',
      onClick: '/website-tools',
    },
    
    {
      id: 3,
      icon: <SiGoogleads />,
      text: 'Advertisement',
      onClick: '/ads',
    },
  ];

  return (
    <div className="flex text-[.845rem] items-center gap-2 tracking-wider pl-3">
      <Link to="/">
        <div className={` ${activeCategory === 'home' ? 'nav-active bg-[#383e47]  ' : ''} nav cursor-pointer`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            viewBox="0 0 200 220"
            fill="none"
          >
            <path
              d="M10 80L100 10L190 80V190C190 195.304 190 207 190 210C185.5 210 175.304 210 170 210H124V147.5H100H76V210H30C24.6957 210 12.5002 210 10.0003 210C9.99984 206 10 195.304 10 190V80Z"
              fill="white"
            />
          </svg>
        </div>
      </Link>

      {/* INBOX */}
      <Link to="/inbox/all-conversation">
        <div className={` ${activeCategory === 'inbox' ? 'nav-active bg-[#383e47]  ' : ''} cursor-pointer h-full flex  items-center nav justify-center  min-w-[4.5rem] relative hover:bg-[#383e47]  hover:text-[#fafbfd] `}>
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] font-semibold ">
            Inbox
          </span>
        </div>
      </Link>
      {/* PAYMENTS */}
      <Link to="/contacts">
        <div className={` ${activeCategory === 'contacts' ? 'nav-active bg-[#383e47]  ' : ''} cursor-pointer h-full flex items-center nav justify-center  min-w-[4.5rem] relative hover:bg-[#383e47]  hover:text-[#fafbfd] `}>
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] font-semibold ">
            Contacts
          </span>
        </div>
      </Link>

      {/* MARKETING */}
      {/* <Link to="/marketing"> */}
      <div className="relative">
        <button type="button" onClick={() => setshowMarketing(true)} className={` ${(activeCategory === 'marketing' || activeCategory === 'website-tools') ? 'nav-active bg-[#383e47]  ' : ''} cursor-pointer h-full flex gap-1 items-center nav justify-center  min-w-[4.5rem] tracking-wider relative hover:bg-[#383e47]  hover:text-[#fafbfd] `}>
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] ">
            Marketing
          </span>
          <FaAngleDown className="mt-[2px]" />
        </button>
        {showMarketing && <NavigationMenu setshowMarketing={setshowMarketing} options={MarketingOptions} /> }
      </div>
      {/* </Link> */}
      {/* REVIEW */}
      <Link to="/reviews/all-reviews">

        <div className={` ${activeCategory === 'reviews' ? 'nav-active bg-[#383e47]  ' : ''} cursor-pointer h-full flex items-center nav justify-center  min-w-[4.5rem] relative hover:bg-[#383e47]  hover:text-[#fafbfd] `}>
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] ">
            Reviews
          </span>
        </div>
      </Link>
      {/* AUTOMATIONS */}
      <Link to="/automations">

        <div className={` ${activeCategory === 'automations' ? 'nav-active bg-[#383e47]  ' : ''} cursor-pointer h-full flex items-center nav justify-center  min-w-[4.5rem] relative hover:bg-[#383e47]  hover:text-[#fafbfd] `}>
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] ">
            Automation
          </span>
        </div>
      </Link>
      {/* REPORTING */}
      {/* <Link to="/reports">

        <div className=" cursor-pointer h-full flex items-center nav justify-center  min-w-[4.5rem] relative hover:bg-[#383e47]  hover:text-[#fafbfd] ">
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] ">
            Reporting
          </span>
        </div>
      </Link> */}
      {/* INBOX */}
      <Link to="/digital-showroom/business-listings">

        <div className={` ${activeCategory === 'digital-showroom' ? 'nav-active bg-[#383e47]  ' : ''} cursor-pointer h-full flex items-end nav justify-center  min-w-[4.5rem] relative hover:bg-[#383e47]  hover:text-[#fafbfd] `}>
          <span className="semibold flex items-center justify-center  rounded-lg w-full  hover:text-[#fafbfd] ">
            Digital Showroom
          </span>
        </div>
      </Link>
    </div>
  );
}

export default NavTabs;
