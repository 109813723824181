import axios from 'axios';
import { baseURL, companyId, config } from '../slice/HEADERS';

const getAllTags = (page,search) => axios.get(`${baseURL}/tags/get-all/${companyId}?page=${page}&pageSize=40&search=${search}`,config);

const createTag = (payload) => axios.post(`${baseURL}/tags/create/${companyId}`,payload,config);

const updateTag = (id,payload) => axios.put(`${baseURL}/tags/update/${id}/${companyId}`,payload,config);

const deleteTag = (id) => axios.delete(`${baseURL}/tags/delete/${id}/${companyId}`,config);

const tagsApi = {
  getAllTags,
  createTag,
  updateTag,
  deleteTag,
 
};
    
export default tagsApi;
