/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { TextField } from '@mui/material';
import { HiChatBubbleOvalLeftEllipsis } from 'react-icons/hi2';
import React, { useEffect, useState } from 'react';
import { getContrastColor } from './Bubble';
import '../../../../Animations.css';

function Body({
  headerText, 
  setHeaderText,
  headerSubText,
  setHeaderSubText,
  headerColor,
  setHeaderColor,
  buttonColor, 
  setButtonColor,
}) {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  // Function to log the inner height
  const logInnerHeight = () => {
    setInnerHeight(window.innerHeight);
  };

  // Effect to add and remove the resize event listener
  useEffect(() => {
    // Add an event listener for the 'resize' event
    window.addEventListener('resize', logInnerHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', logInnerHeight);
    };
  }, []); 
  //   console.log(innerHeight);
  const textColor = getContrastColor(headerColor);
  const buttontextColor = getContrastColor(buttonColor);

  return (
    <div>
      <div className={`z-[1] shadow-slate-400  shadow-lg absolute right-[40px] bottom-[30px] font-sans sm:drop-shadow-xl bg-[#FEFDFD] sm:border-[#e9e9eb]-700 sm:rounded-[10px]
             ${innerHeight < 835 ? 'h-[530px] w-[300px]' : 'h-[590px] w-[330px]'} overflow-hidden`}
      >
        <div className="h-full w-full flex flex-col items-center justify-start bg-[#f5f5f7]">
          <div className={` ${innerHeight < 835 ? 'h-[507px] ' : 'h-[568px]'}  bg-[#f5f5f7] w-full`}>
            <div className="h-full">
              <div className=" items-center relative h-[95px] sm:h-[71px]  flex justify-center sm:rounded-t-[8px]" style={{ backgroundColor: headerColor, color: textColor }}>
                <HiChatBubbleOvalLeftEllipsis className="text-[30px]" />
                <div className="flex flex-col w-[calc(100%-125px)] justify-center h-fit ml-[1rem]">
                  <div className="text-[15px] medium text-ellipsis overflow-hidden h-[1.2rem] leading-[20px] font-bold ">{headerText}</div>
                  <div className="text-[12px] leading-[20px] h-[1rem] text-ellipsis overflow-hidden">{headerSubText}</div>
                </div>
                <div className="absolute right-1 top-1 sm:hidden pt-[8px] pr-[8px] text-[20px] leading-[28px]">
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                </div>
              </div>
              <div className="h-[calc(100%-13px)] bg-[#f5f5f7] rounded-b-[8px] animateWebchat one">
                <div className="h-[calc(100%-61px)] ">
                  <div className="h-full">
                    <div className="flex flex-col justify-between h-full pt-5">
                      <div className={` ${innerHeight < 835 ? 'px-[20px] ] ' : 'px-[16px] '} border-solid space-y-1 sm:space-y-0`}>
                        <div className="sm:space-y-5 space-y-7 flex flex-col">
                          <div className={`p-[20px] scale-in-bl-automation rounded-t-[35px] rounded-br-[35px]  ${innerHeight < 835 ? 'w-[244px]' : ' w-[274px]'} text-[14px] font-normal text-[#333] bg-[#e4e9f0]`}>Enter your information, and our team will text you shortly</div>
                          <div className={`self-end px-[24px] pb-[30px] pt-[12px] flex flex-col items-center relative scale-in-br-automation ${innerHeight < 835 ? 'w-[244px]' : ' w-[274px]'}  bg-[#ffff] rounded-t-[35px] rounded-bl-[35px]  text-[16px] space-y-3 mb-3`}>                           
                           
                            <input type="text" placeholder="Name" className="w-[90%] outline-none border-b-[#E9E3FE] border-b-[1px] h-[32px] placeholder:text-[12px] placeholder:text-gray-500 pointer-events-none" />
                            <input type="text" placeholder="Phone" className="w-[90%] outline-none border-b-[#E9E3FE] border-b-[1px] h-[32px] placeholder:text-[12px] placeholder:text-gray-500 pointer-events-none" />
                            <input type="text" placeholder="Message" className="w-[90%] outline-none border-b-[#E9E3FE] border-b-[1px] h-[32px] placeholder:text-[12px] placeholder:text-gray-500 pointer-events-none" />
                            
                            {/* <TextField
                              className="w-[90%]  pointer-events-none"
                              id="standard-basic"
                              label="Name"
                              variant="standard"
                              sx={{
                                '& .MuiTextField-root': {
                                  color: 'black',
                                  fontSize: '14px',
                                  transition: 'border-color 0.3s ease',
                                  borderBottom: '1px solid #E9E3FE',

                                },
                                // '&:hover': {
                                //   borderColor: 'unset !important', // Prevent border color change on hover
                                // },
                                // '&:hover fieldset': {
                                //   borderWidth: '0px !important', // Prevent border width change on hover
                                // },
                                ' label': {
                                  fontSize: '12px !important',
                                },
                                '& .MuiInputLabel-root': {
                                //   transition: 'none', 
                                },
                              }}
                            />
                            <TextField
                              className="w-[90%] hover:!outline-none pointer-events-none"
                              id="standard-basic"
                              label="Phone"
                              variant="standard"
                              sx={{
                                // '&:hover': {
                                //   borderColor: 'unset !important', // Prevent border color change on hover
                                // },
                                // '&:hover fieldset': {
                                //   borderWidth: '1px !important', // Prevent border width change on hover
                                // },
                                '& label': {
                                  fontSize: '12px !important',
                                },
                                '& .MuiInputLabel-root': {
                                  //   transition: 'none', 
                                },
                                '& .MuiInputBase-input': {
                                  color: 'black',
                                  fontSize: '14px',
                                  transition: 'border-color 0.3s ease',
                                  borderBottom: '1px solid #E9E3FE',
                                },
                              }}
                            />
                            <TextField
                              className="w-[90%] hover:!outline-none pointer-events-none"
                              id="standard-basic"
                              label="Message"
                              variant="standard" 
                              sx={{
                                // '&:hover': {
                                //   borderColor: 'unset !important', // Prevent border color change on hover
                                // },
                                // '&:hover fieldset': {
                                //   borderWidth: '1px !important', // Prevent border width change on hover
                                // },
                                ' label': {
                                  fontSize: '12px !important',
                                },
                                '& .MuiInputLabel-root': {
                                //   transition: 'none', 
                                },
                              }}
                            /> */}
                          </div>
                        </div>
                        {/* <div className="px-[5px] pt-1 flex justify-center text-[11px] leading-[14px] text-red-400 break-words"> </div> */}
                        <div className="space-y-6 flex flex-col items-center">
                          <button
                            className="rounded-[8px] flex items-center justify-center gap-2 px-[24px] w-[45%] mt-[20px]  h-[36px] "
                            style={{
                              backgroundColor: buttonColor, color: buttontextColor, height: '36px', 
                            }}
                          >
                            Send
                          </button>
                          <div className="px-[20px]  text-justify text-[10px] leading-[16px] break-words text-[#535355]">
                            
                            {innerHeight < 835 ? 'By submitting, you authorize  to send text messages with offers, other information.' : 'By submitting, you authorize  to send text messages with offers , other information, possibly using automated technology, to the number you provided.'}
                            {' '}
              
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#fff] flex justify-center items-center relative text-[12px] pb-2 h-[1.5rem] border-t pt-2 w-full rounded-b-[8px]">
            <span className="text-[#535355]"> Powered by</span>
            <div className="flex items-center ml-1 cursor-pointer">
              {/* <img src="https://s3.us-east-2.amazonaws.com/sc-prod-embedded.salescaptain.com/resources/2023-07-22/sales_captain_1690015901642.png" className=" h-[18px]" alt="" /> */}
              <span className="font-semibold ml-1">Elyzian</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Body;
