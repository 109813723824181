import axios from 'axios';
import { baseURL,companyId, config } from '../slice/HEADERS';

const sendWaMessage = (payload, id) => axios.post(`${baseURL}/whatsapp-messaging/sendWaMsg/${id}/${companyId}`,payload,config);

const replyToMessage = (payload,id) => axios.post(`${baseURL}/whatsapp-messaging/reply-To-Message/${id}/${companyId}`,payload,config);

const getAllMessages = (page,contactId, userId, displayName) => axios.get(`${baseURL}/whatsapp-messaging/get-all-messages/${contactId}/${userId}/${companyId}?displayName=${displayName}&page=${page}&pageSize=10`,config);

const getMessageByWamid = (msgId) => axios.get(`${baseURL}/whatsapp-messaging/get-message-by-id/${msgId}/${companyId}`,config);

const updateConversation = (payload) => axios.post(`${baseURL}/inbox/update-conversation/${companyId}`, payload, config);

// const getAllConversationList = (page,search, isOpen) => axios.get(`${baseURL}/contacts/get-all/${companyId}?page=${page}&pageSize=20&search=${search}&isOpne=${isOpen}`,config);

const assignUser = (payload) => axios.post(`${baseURL}/inbox/assign-user/${companyId}`, payload, config);

// const assignUserToMultiple = (payload) => axios.post(`${baseURL}/inbox/assign-user-to-multiple-conv/${companyId}`, payload, config);

const getAllConversationList = (page, search, isOpen, assignedTo) => axios.get(`${baseURL}/inbox/get-all-conversation/${companyId}`, {
  params: {
    page,
    pageSize: 20,
    search,
    isOpen,
    assignedTo,
  },
  config, 
});

const getConversationAnalytics = (id) => axios.get(`${baseURL}/whatsapp-management/get-analytics/${id}/${companyId}`, config);

const sendWebchatMessage = (payload, id) => axios.post(`${baseURL}/webchat/send-to-webchat-message/${id}/${companyId}`,payload,config);

const inboxApi = {
  sendWaMessage,
  getAllMessages,
  updateConversation,
  getAllConversationList,
  assignUser,
  replyToMessage,
  getMessageByWamid,
  getConversationAnalytics,
  sendWebchatMessage,
};
  
export default inboxApi;
