import React, { useEffect, useState } from 'react';
import CustomTextEditor from './CustomTextEditor';

const CTBody = ({
  body,setBody,Language,
}) => {
  const [bodyText, setBodyText] = useState('');
  const [variables, setVariables] = useState([]);
  const [variableContent, setVariableContent] = useState([]);
  const [variableCounter, setVariableCounter] = useState(1);

  console.log('body',body,variables);

  useEffect(() => {
    setBody(getBody(bodyText,variableContent));
  },[bodyText,variableContent]);

  function getBody(bText, bodyTextVariable) {
    const bodyToSend = {
      type: 'BODY',
      text: bText,
    };
  
    if (bodyTextVariable?.length > 0) {
      bodyToSend.example = {
        body_text: bodyTextVariable, // Array of string
      };
    }
  
    return bodyToSend;
  }

  return (
    <div className="my-7">
      <div className="text-sm medium flex items-center">
        Body
        {/* <span className="text-[0.7rem] bg-gray-100 py-[1px] ml-3 rounded-lg px-1 text-center">Optional</span> */}
      </div>
      <p className="text-[#1c2b33a6] text-[13px] my-3">Enter the text for your message in the language you&apos;ve selected.</p>
   
      <CustomTextEditor 
        bodyText={bodyText}
        setBodyText={setBodyText}
        Language={Language}
        variables={variables}
        setVariables={setVariables}
        variableContent={variableContent}
        setVariableContent={setVariableContent}
        variableCounter={variableCounter}
        setVariableCounter={setVariableCounter}
      />
    </div>
  ); 
};

export default CTBody;
