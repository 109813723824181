import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  paper: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius: '11px',
      width: 'max-content',
      marginTop: '5px',
      padding: '8px',
      border: '1px solid #E0E6EE',
      boxShadow: ' -1px 3px 18px -7px   rgba(43,112,230,0.5)',
      // minWidth: 150,
      // maxWidth: 1400,
    },
  },
};

export default function SimpleMenu({
  buttonText, menuOptions, setValue,blue, icon,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    console.log('e',e.value);
    setValue(e.value);
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="border w-max rounded-lg buttonShadow  !shadow-md  ">
        <Button
          id="fade-button"
          sx={{ fontFamily: 'GraphikRegular' }}
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={`!flex !gap-1 !h-8 !justify-center !items-center !w-[130px] !text-[0.84rem] !tracking-wide   hover:!shadow ${blue ? 'focus:!bg-[#1E47F6]  hover:!bg-[#1E47F6] focus:!ring-4 focus:!ring-blue-200    !bg-[#466EFF]  !text-white hover:!transition hover:!delay-200 !border-[0.5px] !border-blue-700' : ' !text-[#1A202c]  !border hover:!bg-white hover:!border-[1px] !bg-gray-50 !border-gray-300'}  !rounded-lg !medium !p-2 !capitalize `}
          onClick={handleClick}
        >
          { icon && icon }

          {buttonText}
        </Button>
      </div>
      <Menu
        id="fade-menu"
        slotProps={MenuProps}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        // MenuListProps={MenuProps}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuOptions.map((e) => (
          <MenuItem
            key={e}
            className="hover:!bg-[#466EFF] !mt-1 hover:!text-white !rounded-[10px] !text-[#1a202c]"
            onClick={() => handleClose(e)}
          >
            {e.label}

          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
