import React from 'react';
import './bubble.css';
import { RiWhatsappFill } from 'react-icons/ri';
import { MdOutlineContentCopy , MdOutlineReply } from 'react-icons/md';
import { BiLinkExternal } from 'react-icons/bi';
import { IoCall } from 'react-icons/io5';

import chatbackground from '../../../Assets/chatbackground.png';

const CTMessagePreview = ({ data }) => (
  <div className="h-full ">
    <div className="h-[68px] flex items-center gap-2 pl-5 pr-3 py-5 medium text-base text-[#1A202C] border-b ">
      Message Preview 
      {' '}
      <RiWhatsappFill className="text-green-500 text-lg" />
    </div>
    <div
      style={{
        backgroundImage: `url(${chatbackground})`,
        backgroundSize: 'cover',
      }}
      className="h-[calc(100%-68px)] "
    >
      <div className="">
        {/* <div className="bubble shadow-lg"> */}
        <div className="speech-wrapper-template ">
          <div className="bubble-template shadow-lg">
            <div className="txt-template  relative">
              {data?.components.map((component) => (
                <div>
                  {component?.type === 'HEADER' && (
                    <div className="name-template">{component?.text}</div>
                  )}
                  <p className="message-template">
                    {component?.type === 'BODY' && (`${component?.text}`)}
                  </p>
                </div>
              ))}
              
              <span className="timestamp-template">10:20 pm</span>
            </div>
            <div className="bubble-arrow-template" />
            <div className="mt-3">
              {data?.components.map((component) => (
                <div>
                  {component?.type === 'BUTTONS' && (
                    <div>
                      {component?.buttons?.map((button) => (
                        <div className="border-t  px-4 py-2 text-[0.73rem] flex items-center gap-1 justify-center">
                          {button.type === 'COPY_CODE' && (
                            <MdOutlineContentCopy className="text-blue-400" />
                          )}

                          {button.type === 'PHONE_NUMBER' && (
                            <IoCall className="text-blue-400" />
                          )}

                          {button.type === 'URL' && (
                            <BiLinkExternal className="text-blue-400" />
                          )}
                          {button.type === 'QUICK_REPLY' && (
                            <MdOutlineReply className="text-blue-400" />
                          )}
                          <span className="text-blue-500">{button?.text}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  {' '}
                  
                </div>
              ))}
          
            </div>
           
          </div>
          
        </div>
        <div className="bubble-arrow" />
        {/* </div> */}
      </div>
    </div>

    {/* <div
      style={{
        backgroundImage: `url(${chatbackground})`,
        backgroundSize: 'cover',
      }}
      className="h-[calc(100%-68px)] "
    >
      <div className="speech-wrapper-template ">
        <div className="bubble-template shadow-lg">
          <div className="txt-template  relative">
            {data?.components.map((component) => (
              <div>
                {component?.type === 'HEADER' && (
                <div className="name-template">{component?.text}</div>
                )}
                <p className="message-template">
                  {component?.type === 'BODY' && (component?.text?.length > 30 ? `${component?.text.slice(0,60)} ...` : `${component?.text}`)}
                </p>
              </div>
            ))}
              
            <span className="timestamp-template">10:20 pm</span>
          </div>
          <div className="bubble-arrow-template" />
          <div className="mt-3">
            {data?.components.map((component) => (
              <div>
                {component?.type === 'BUTTONS' && (
                <div>
                  {component?.buttons?.map((button) => (
                    <div className="border-t  px-4 py-2 text-[0.73rem] flex items-center gap-1 justify-center">
                      <IoCall className="text-blue-400" />
                      <span className="text-blue-500">{button?.text}</span>
                    </div>
                  ))}
                </div>
                )}
                {' '}
                  
              </div>
            ))}
          
          </div>
           
        </div>
          
      </div>
    </div> */}

  </div>
);

export default CTMessagePreview;
