import React from 'react';
import hours from '../../../Assets/hours.jpg';

function BusinessHours() {
  return (
    <div className=" p-10 w-full h-full ">
      <div className="flex justify-between pb-4 ">
        <span className="text-2xl medium text-[#1A202c]">Business Hours</span>       

      </div>
      <img src={hours} alt="" />
    </div>
  );
}

export default BusinessHours;
