import React from 'react';
import CTHeader from './CTHeader';
import CTBody from './CTBody';
import CTFooter from './CTFooter';
import CTButtons from './CTButtons';
import Button from '../../ReusableComponents/Button';

const CTEditTemplateSection = ({
  header, setHeader, body, setBody,
  Language, setLanguage, footer, setFooter,
  buttons, setButtons,handleSubmit,
}) => (
  <div className="py-5 px-5 2xl:px-6">
    <div className="flex justify-between items-start h-[50px]  border-b">
      <div className="text-base text-[#1A202C] medium">
        Edit template section
      </div>
      <div className="w-[120px]">
        <Button blue buttonText="Submit" onClick={handleSubmit} />
      </div>
    </div>

    <div>
      <CTHeader 
        header={header}
        setHeader={setHeader}
        body={body}
        setBody={setBody}
        Language={Language} 
        setLanguage={setLanguage}
        handleSubmit={handleSubmit}
      />
    </div>

    <div>
      <CTBody
        body={body}
        setBody={setBody}
        Language={Language}
      />
    </div>

    <div>
      <CTFooter
        footer={footer}
        setFooter={setFooter}
        Language={Language} 

      /> 
    </div>

    <div>
      <CTButtons
        buttons={buttons}
        setButtons={setButtons}
      />
    </div>

  </div>
);

export default CTEditTemplateSection;
