import React, { useEffect, useRef } from 'react';
import { RiDeleteBinLine,RiUserFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Backdrop } from '@mui/material';
import { getColorForAlphabet } from '../../Common/Colors';

function Profile({
  profileOptions,regex,setshowProfile,showProfile, handleLogout,userData,
}) {
  const closePopUp = (ref) => {
    useEffect(() => {     
      const handleClickOutside = (event) => {
        // console.log('ref event',event,ref);
        if (ref.current && !ref.current.contains(event.target)) {
          setshowProfile(false);
          // alert('clicked outside');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  closePopUp(wrapperRef);

  return (
    <Backdrop
      sx={{ color: '#fff',backgroundColor: '#00000033', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showProfile}
    //   onClick={handleClose}
    >

      <div ref={wrapperRef} className="absolute scale-in-tr z-10 w-[332px] h-[250px] bg-[#1b1f26] text-white rounded-lg right-[24px] top-[60px]">
        <div className="p-5 h-[112px] flex items-center"> 
          <div className="flex  gap-3 items-center">
            <div
              style={{
                backgroundColor: regex.test(userData.data.firstName?.slice(0, 1)?.toUpperCase()) ? '#D2FAF1' : getColorForAlphabet(userData.data.firstName?.slice(0, 1)?.toUpperCase()),
              }}
              className="h-12 w-12 rounded-[14px] relative  flex items-center justify-center cursor-pointer "
            >
              <span className="text-white inbox-message-regular text-[14px]">
                {regex.test(userData.data.firstName?.slice(0, 1)?.toUpperCase()) 
                  ? <RiUserFill className="!text-[#2EB597] !text-[24px] mt-1" />
                  : `${userData.data.firstName?.slice(0, 1)?.toUpperCase()}${userData.data.lastName?.slice(0, 1)?.toUpperCase()}`}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="medium text-base">{userData.data.displayName}</span>
              <span className="text-xs text-[#c8cfd9]">{userData.data.email}</span>
            </div>

          </div>
        </div>
        <div className="py-3 border-t-[1px] border-[#383e47]">
          { profileOptions?.map((e) => (
            <div
              key={e.id}
              onClick={() => {
                if (typeof e.onClick === 'function') {
                  e.onClick();
                } else {
                  navigate(e.onClick);
                  setTimeout(() => {
                    setshowProfile(false);
                  }, 200);
                }
              }}
              className="flex hover:bg-[#292e36] rounded-xl items-center gap-3 px-5 py-3 text-xs cursor-pointer"
            >
              <span className="text-base">{e.icon}</span>
              <span className="medium">{e.text}</span>
            </div>
          ))}

        </div>
      </div>
    </Backdrop>
  );
}

export default Profile;
