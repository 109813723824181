import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchAppointments } from '../../../slice/appointmentSlice';
import AppointmentList from './AppointmentList';

function DigitalBookings() {
  const dispatch = useDispatch();

  const appointment = useSelector((state) => state.appointments.data);
  const page = useSelector((state) => state.appointments.page);
  const totalPages = useSelector((state) => state.appointments.totalPages);

  const [currPage, setCurrPage] = useState(1);

  const getAppointments = async (pg) => {
    const response = await dispatch(fetchAppointments({ page: pg })).unwrap();
    console.log('getAppointments res',response);
  };

  console.log('Appointments',currPage,page,totalPages,appointment.length);

  useEffect(() => {
    getAppointments(currPage);
  }, [currPage]);

  const loadMore = () => {
    console.log('-here-');
    setCurrPage(currPage + 1);
  };

  const heightValue = 'calc(100vh - 275px)';

  return (
    <div className=" p-10 w-full h-full overflow-x-hidden ">
      <div className="flex justify-between pb-4 ">
        <span className="text-2xl medium text-[#1A202c]">All Appointments</span>       

      </div>

      <div className="border-t pt-10 mt-4 w-full ">
        <div className="flex flex-col gap-7 w-full">
          {appointment.map((e) => (
            <InfiniteScroll
              dataLength={appointment.length}
              next={loadMore}
              hasMore={currPage < totalPages}
              // loader={<div style={{ marginLeft: '50%' }}>Loading...</div>}
              // height={heightValue}
            >
              <AppointmentList key={e._id} e={e} />
            </InfiniteScroll>
          ))}         
        </div>
      </div>
    
    </div>
  );
}

export default DigitalBookings;
