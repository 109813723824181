import axios from 'axios';
import {
  baseURL, companyId, config, fileConfig, 
} from '../slice/HEADERS';

const getCompanyById = (id) => axios.get(`${baseURL}/company/${id}`,config);

const uploadFile = (data) => axios.post(`${baseURL}/upload/${companyId}`,data,fileConfig);

const companyApi = {
  getCompanyById,
  uploadFile,
};
  
export default companyApi;
