import axios from 'axios';
import { baseURL, companyId, config } from '../slice/HEADERS';

const getUserById = (id) => axios.get(`${baseURL}/users/get/${id}`,config);
const getAllUsers = () => axios.get(`${baseURL}/users/get-all/${companyId}`,config);
const createUser = (payload) => axios.post(`${baseURL}/users/create-users/${companyId}`,payload,config);

const UsersApi = {
  getAllUsers,
  createUser,
  getUserById,
};
  
export default UsersApi;
