import axios from 'axios';
import { baseURL,companyId, config } from '../slice/HEADERS';

const createContact = (payload) => axios.post(`${baseURL}/contacts/create/${companyId}`,payload,config);

const getAllContacts = (page,search) => axios.get(`${baseURL}/contacts/get-all/${companyId}?page=${page}&pageSize=20&search=${search}`,config);

const getContactById = (id) => axios.get(`${baseURL}/contacts/get-by-id/${id}/${companyId}`,config);

const updateContact = (id,payload) => axios.put(`${baseURL}/contacts/update/${id}/${companyId}`,payload,config);

const deleteContact = (id) => axios.delete(`${baseURL}/contacts/delete/${id}/${companyId}`,config);

const uploadContacts = (payload) => axios.post(`${baseURL}/contacts/upload/${companyId}`,payload,config);

const contactsApi = {
  createContact,
  getAllContacts,
  getContactById,
  updateContact,
  deleteContact,
  uploadContacts,
};
  
export default contactsApi;
