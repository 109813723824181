import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import templatesApi from '../Apis/Template';

// Create an async thunk for fetching all contacts
export const fetchAllTemplates = createAsyncThunk('templates/fetchAll', async () => {
  const response = await templatesApi.getAllTemplates();
  return response.data; 
});

export const createTemplates = createAsyncThunk('templates/createTemplates', async (data) => {
  const response = await templatesApi.createTemplates(data);
  return response.data; 
});

// Create the initial state for your templates slice
const initialState = {
  templates: [],
  loading: false,
  error: null,
};

// Create the review slice
const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.templates = action.payload.data.data;
      })
      .addCase(fetchAllTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// export { fetchAllTemplates };
export default templateSlice.reducer;
