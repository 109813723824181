// EmployeeInput.js
import React from 'react';
import SelectOptions from '../../ReusableComponents/SelectOptions';

const menuOptions = [
  { label: 'OWNER', value: 'OWNER' },
  { label: 'MANAGER', value: 'MANAGER' },
  { label: 'TEAM MEMBER', value: 'TEAM_MEMBER' },
];
const EmployeeInput = ({
  email, setEmail, role, setRole,handleInputChange,
  isEmailValid, 
}) => (
  <div>
    <div className="flex items-center gap-5">
      <input
        type="text"
        placeholder="Employees Email"
        value={email}
        onChange={handleInputChange}
        className={`${isEmailValid ? `resize-none overflow-hidden focus:ring-4 shadow-sm
            shadow-gray-200 border block w-[80%]      
            text-gray-900 text-sm rounded-lg outline-none h-[34px]
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
          : `resize-none overflow-hidden focus:ring-4 shadow-sm
            shadow-gray-200 border block w-[80%]      
            text-gray-900 text-sm rounded-lg outline-none h-[34px]
            focus:border-[1px] focus:ring-red-200 focus:border-red-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide`
        }`}
      />
      <SelectOptions 
        label="Role type"
        value={role}
        setValue={setRole}
        menuOptions={menuOptions}
        size="small"
        minWidth="20%"
      />
    </div>

    <div>
      {!isEmailValid && (
      <p className="text-red-500">Enter valid email</p>
      )}
    </div>
  </div>
);

export default EmployeeInput;
