import React from 'react';
import { FcGoogle } from 'react-icons/fc';

function BusinessListings() {
  return (
    <div className=" p-10 w-full h-full ">
      <div className="flex justify-between pb-4 ">
        <span className="text-2xl medium text-[#1A202c]">Business Listings</span>       
    
      </div>

      <div className="bg-white flex justify-between px-14 py-6 border-y-[2px] mt-6 ">
        <div className="flex items-center gap-2 medium ">
          <FcGoogle className="text-[22px]" />
          Google
        </div>
        <div className="flex items-center gap-2 medium">
          <span className="text-[12px]">Status:</span>
          <span className="bg-green-600 tracking-wide rounded-full border-green-800 px-3 py-[2px] text-xs  text-white">Connected</span>

        </div>
      </div>
    
    </div>
  );
}

export default BusinessListings;
