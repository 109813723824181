/* eslint-disable react/jsx-props-no-multi-spaces */
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

export default function MouseOverPopover({
  mainContent, popoverContent, 
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // console.log('popoverContent?>>',popoverContent);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>     
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverClose}

      >
        {mainContent}
      </Typography>

      <Popover
        id="mouse-over-popover"
        className="tracking-wide"
        sx={{
          pointerEvents: 'none',
          // width: '200px',

          '& .MuiPopover-root ': {
            border: 'none',

          },
          '& .MuiPopover-paper ': {
            // width: '200px',
            borderRadius: '7px',
            // border: '1px solid #383E47',

          },
        }}
        style={{
          marginTop: '22px',
         
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        // className={type === 'leads' ? showPopOver ? 'block' : 'hidden' : 'block'}
      >
        <div
          style={{
            background: '#383E47',
            // borderRadius: '6px',
            color: '#ffffffa3',
            padding: '7px',
            paddingLeft: '13px',
            paddingRight: '13px',
            fontSize: '11.5px',
          }}
        >
          {popoverContent}
        </div>
        {/* <Typography sx={{ p: 1 }}>{popoverContent}</Typography> */}
      </Popover>
    </div>
  );
}
