import React from 'react';
import CTLanguageSection from './CTLanguageSection';
import CTEditTemplateSection from './CTEditTemplateSection';
import CTMessagePreview from './CTMessagePreview';
import Button from '../../ReusableComponents/Button';
import { boxShadowStyle } from '../../Common/Colors';

const CTStep2 = ({
  header, setHeader, body, setBody,
  Language, setLanguage, footer, setFooter,
  buttons, setButtons,handleSubmit,payload,
}) => (
  <div className="2xl:p-10 p-6 pt-10 w-full min-h-full  ">
    <div className="flex pl-5 pr-3 justify-between bg-white h-16 items-center border-[1px] rounded-lg shadow-md">
      <div className="text-lg text-[#1A202C] medium">
        Template name
      </div>
      <div className="w-28">
        <Button buttonText="Back" />
      </div>
    </div>
    <div className="w-full flex h-[calc(100%-85px)] mt-5 gap-3">
      <div className="w-[20%] 2xl:w-[20%] border-[1px] rounded-lg shadow-md bg-white">
        <CTLanguageSection />
      </div>
      <div className="w-[55%] 2xl:w-[60%]  border-[1px] rounded-lg shadow-md bg-white">
        <CTEditTemplateSection
          header={header}
          setHeader={setHeader}
          body={body}
          setBody={setBody}
          Language={Language} 
          setLanguage={setLanguage}
          footer={footer}
          setFooter={setFooter}
          buttons={buttons}
          setButtons={setButtons}
          handleSubmit={handleSubmit}
        />
      </div>
      <div className="w-[25%] 2xl:w-[20%] border-[1px] rounded-lg overflow-hidden shadow-md bg-white">
        <CTMessagePreview data={payload} />
      </div>
    </div>
  </div>
);

export default CTStep2;
