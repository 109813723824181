// contactSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import contactsApi from '../Apis/Contacts';

const initialState = {
  data: [],
  filterData: [], 
  isFilter: false,
  isLoading: false,
  isError: false,
  totalContacts: 0,
  filteredContacts: 0,
  page: 0,
  totalPages: 1,
  filteredTotalPages: 1,
  search: '', // Add this field to store the search term
};

export const createContact = createAsyncThunk(
  'data/createContact', // Action type
  async ({ payload }, thunkAPI) => {
    const response = await contactsApi.createContact(payload);
    return response.data;
  },
);

// export const getAllContacts = createAsyncThunk(
//   'data/getAllContacts', // Action type
//   async (page, thunkAPI) => {
//     const response = await contactsApi.getAllContacts(page);
//     return response.data;
//   },
// );

export const getAllContacts = createAsyncThunk(
  'data/getAllContacts', // Action type
  async ({ page, search }, thunkAPI) => {
    const response = await contactsApi.getAllContacts(page,search);
    return response.data;
  },
);

export const fetchContactById = createAsyncThunk(
  'data/fetchContactById', // Action type
  async ({ id }, thunkAPI) => {
    const response = await contactsApi.getContactById(id);
    return response.data;
  },
);

export const updateContact = createAsyncThunk(
  'data/updateContact', // Action type
  async ({ id,payload }, thunkAPI) => {
    const response = await contactsApi.getCompanyById(id, payload);
    return response.data;
  },
);

export const deleteContact = createAsyncThunk(
  'data/deleteContact', // Action type
  async ({ id,companyId,payload }, thunkAPI) => {
    const response = await contactsApi.getCompanyById(id, companyId, payload);
    return response.data;
  },
);

export const uploadContacts = createAsyncThunk(
  'data/uploadContacts', // Action type
  async (payload, thunkAPI) => {
    const response = await contactsApi.uploadContacts(payload);
    return response.data;
  },
);

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.filterData = action.payload;
      state.isFilter = false;
      state.filteredTotalPages = 1;
      // console.log('==here==');
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getAllContacts.pending, (state) => {
      //   state.isLoading = true;
      //   state.isError = false;
      // })
      // .addCase(getAllContacts.fulfilled, (state, action) => {
      //   // const { page, search } = action.meta.arg;
      //   state.isLoading = false;
      //   state.isError = false;
      //   state.data = [...state.data, ...action.payload.data.contacts];
      //   const page = action.meta.arg;
      //   state.page = page;
      //   state.totalContacts = action.payload.data.totalContacts;
      //   state.totalPages = action.payload.data.totalPages;
      // })
      // .addCase(getAllContacts.rejected, (state) => {
      //   state.isLoading = false;
      //   state.isError = true;
      // })
      .addCase(getAllContacts.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        const { page, search } = action.meta.arg;
        state.isLoading = false;
        state.isError = false;
        if (search) {
          console.log('is itin search???');
          state.isFilter = true;
          // state.filterData = [...state.filterData, ...action.payload.data.contacts];
          // If on page 1, replace the existing data; otherwise, append to it
          if (page === 1) {
            state.filterData = action.payload.data.contacts;
          } else {
            // state.data = [...state.data, ...action.payload.data.contacts];
            state.filterData = [...state.filterData, ...action.payload.data.contacts];
          }
          state.filteredContacts = action.payload.data.totalContacts;
          state.filteredTotalPages = action.payload.data.totalPages;
        } else {
          state.isFilter = false;
          // state.filterData = [];
          state.data = [...state.data, ...action.payload.data.contacts];
          state.page = page;
          state.totalContacts = action.payload.data.totalContacts;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(getAllContacts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(createContact.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = [action.payload.data,...state.data]; // Add the new contact to the data array
        state.totalContacts += 1; // Increment the totalContacts count
      })
      .addCase(createContact.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
  
export const { setSearchData } = contactSlice.actions;

export default contactSlice.reducer;
