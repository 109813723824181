/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import { makeStyles } from '@mui/styles';
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#466eff' : '#466eff',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
     
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
    
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
          
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 15,
    marginTop: '0.5px',
    // marginRight: '0.5px',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

}));
// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiFormControlLabel-label': {
//       fontFamily: 'GraphikMedium',
//       fontSize: '14px',
//       color: '#292E36',
//     },
  
//   },
// }));
function CustomSwitch({
  value, setValue, switchOn, switchOff, onChange,name,label,
}) {
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <div className="">
      {/* <div className="text-sm text-[#292E36]">
        {label}
      </div> */}
      {/* <FormControlLabel
        // classes={{ root: classes.root }}
        sx={{
          '& .MuiFormControlLabel-label': {
            fontFamily: 'GraphikMedium',
            color: '#292E36',
            marginTop: '5px',
            fontSize: '14px',
          },
        }}
        control={(
          <IOSSwitch
            sx={{ m: 1 }}
            checked={value}
            name={name}
            onChange={onChange || handleChange}
          />
        )}
        label={value ? switchOn : switchOff}
      /> */}
      {switchOff && <span className="text-sm medium text-[#292e36]">{switchOff}</span> }

      <IOSSwitch
        sx={{ m: 1 }}
        checked={value}
        name={name}
        onChange={onChange || handleChange}
      />
      {switchOn && <span className="text-sm medium text-[#292e36]">{switchOn}</span>}

    </div>
  );
}

export default CustomSwitch;
