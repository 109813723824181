/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import SelectOptions from '../../ReusableComponents/SelectOptions';
import Input from '../../ReusableComponents/Input';
import CTHeaderText from './CTHeaderText';
import CTHeaderImage from './CTHeaderImage';
import CustomTextEditor from './CustomTextEditor';

const headerTypeMenuOptions = [
  { label: 'None',value: 'none' },
  { label: 'Text',value: 'TEXT' },
  { label: 'Image',value: 'IMAGE' },
  { label: 'Video',value: 'VIDEO' },
  { label: 'Document',value: 'DOCUMENT' },
  { label: 'Location',value: 'LOCATION' },
];
const CTHeader = ({
  header, setHeader, Language, setLanguage, 
}) => {
  const [headerType, setHeaderType] = useState('none');

  const [headerText, setHeaderText] = useState('');
  const [variables, setVariables] = useState([]);
  const [variableContent, setVariableContent] = useState([]);
  const [variableCounter, setVariableCounter] = useState(1);

  useEffect(() => {
    setHeader(getHeaders(headerType,headerText,variableContent));
  },[headerType,headerText,variableContent]);

  function getHeaders(format,hText, headerTextVariable,mediaHandle) {
    const headerToSend = {
      type: 'HEADER',
      format,
    };
  
    if (format === 'TEXT') {
      headerToSend.text = hText;
      if (headerTextVariable.length > 0) {
        headerToSend.example = {
          header_text: headerTextVariable, // Array of string
        };
      }
    } else if (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(format)) {
      headerToSend.example = {
        header_handle: mediaHandle, // Array value for header_handle id
      };
    } else if (format === 'LOCATION') {
      // No additional properties needed for LOCATION format
    } else if (format === 'none') {
      return null;
      // console.log();
      // return;
    } else {
      console.log(`Unsupported header format: ${format}`);
      // throw new Error(`Unsupported header format: ${format}`);
    }
  
    return headerToSend;
  }

  console.log('headerText',header);
  return (
    <div className="my-7">
      <div className="text-sm medium flex items-center">
        Header
        <span className="text-[0.7rem] bg-gray-100 py-[1px] ml-3 rounded-lg px-1 text-center">Optional</span>
      </div>
      <p className="text-[#1c2b33a6] text-[13px] my-3">Add a title or choose which type of media you'll use for this header.</p>
      <div className="w-[30%]">
        <SelectOptions
          value={headerType}
          setValue={setHeaderType}
          menuOptions={headerTypeMenuOptions}
          size="small"
          minWidth="130px"
        />
      </div>
      <div>
        {headerType === 'none' && null}
        {headerType === 'TEXT' && (
          <CTHeaderText 
            headerText={headerText}
            setHeaderText={setHeaderText}
            variables={variables}
            setVariables={setVariables}
            variableContent={variableContent}
            setVariableContent={setVariableContent}
            variableCounter={variableCounter}
            setVariableCounter={setVariableCounter}
            Language={Language} 
          />
        )}

        {(headerType === 'IMAGE' || headerType === 'VIDEO' || headerType === 'DOCUMENT') && (
          <CTHeaderImage />
        )}

        {(headerType === 'LOCATION') && (
          <div>Select location</div>
        )}
       
      </div>
    </div>
  ); 
};

export default CTHeader;
