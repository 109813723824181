/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  GrFormAdd,
} from 'react-icons/gr';
import { MdDeleteForever } from 'react-icons/md';
import toast from 'react-hot-toast';
import SimpleMenu from '../../ReusableComponents/Menu';
import SmallIcon from '../../ReusableComponents/SmallIcon';

const menuOptions = [
  { label: 'Custom', value: 'QUICK_REPLY' },
  { label: 'Marketing Opt-out', value: 'MARKETING_OPT_OUT' },
  { label: 'Call phone number', value: 'PHONE_NUMBER' },
  { label: 'Copy offer code', value: 'COPY_CODE' },
  { label: 'Visit website', value: 'URL' },
];

function CTButtons({ buttons, setButtons }) {
  // const [buttons, setButtons] = useState([]);
  const [buttonType, setButtonType] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [offerCode, setOfferCode] = useState('');
  const [url, setUrl] = useState('');
  const [customInput, setCustomInput] = useState('');

  const resetInputFields = () => {
    setButtonText('');
    setPhoneNumber('');
    setOfferCode('');
    setUrl('');
    setCustomInput('');
  };

  console.log('buttons', buttons,buttonType);
  useEffect(() => {
    createButton();
    // Clear input fields when button type changes
    resetInputFields();
  }, [buttonType]);

  const createButton = () => {
    if (buttonType) {
      const newButton = {
        type: buttonType,
        text: buttonText,
      };

      if (buttonType === 'PHONE_NUMBER') {
        newButton.phone_number = phoneNumber;
      } else if (buttonType === 'COPY_CODE') {
        newButton.text = 'Copy offer code';
        newButton.example = offerCode;
      } else if (buttonType === 'URL') {
        if (buttons.filter((btn) => btn.type === 'URL').length < 2) {
          newButton.url = url;
          setButtons([...buttons, newButton]);
        } else {
          // Notify the user or handle the limit reached case
          toast.error('Cannot add more than 2 URL buttons');
        }
      } else if (buttonType === 'QUICK_REPLY') {
        newButton.text = customInput;
        setButtons([...buttons, newButton]);
      } else if (buttonType === 'MARKETING_OPT_OUT') {
        newButton.text = 'Stop promotions';
        setButtons([...buttons, newButton]);
      }

      // Clear the input fields
      resetInputFields();
    }
  };

  const deleteButton = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons.splice(index, 1);
    setButtons(updatedButtons);
  };

  const handleButtonChange = (type) => {
    setButtonType(type);
    // Clear input fields when the button type changes.
    resetInputFields();
  };

  return (
    <div className="mt-7">
      <div className="text-sm medium flex items-center">
        Buttons
        <span className="text-[0.7rem] bg-gray-100 py-[1px] ml-3 rounded-lg px-1 text-center">Optional</span>
      </div>
      <p className="text-[#1c2b33a6] text-[13px] my-3">Create buttons that let customers respond to your message or take action.</p>
      
      <SimpleMenu icon={<GrFormAdd />} buttonText="Add button" menuOptions={menuOptions} setValue={handleButtonChange} />
      {buttons?.length > 0 && (
      <div className="border  my-5 p-5 rounded-lg ">
        <ul className="space-y-5 ">
          {buttons?.map((button, index) => (
            <li className="flex  gap-2 items-center" key={index}>
              <button type="button" className="self-end" onClick={() => deleteButton(index)}>
                <SmallIcon icon={<MdDeleteForever className="text-red-600" />} />

              </button>
              <div className="flex">
                <div className="flex flex-col gap-2">
                  <span className="text-sm medium">Button Text:</span>
                  <input
                    type="text"
                    value={button.text}
                    className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
                    onChange={(e) => {
                      const updatedButtons = [...buttons];
                      updatedButtons[index].text = e.target.value;
                      setButtons(updatedButtons);
                    }}
                  />
                </div>

                {button.type === 'PHONE_NUMBER' && (
                <div className="flex flex-col gap-2">
                  <span className="text-sm medium">Phone Number:</span>
                    
                  <input
                    type="text"
                    value={button.phone_number}
                    className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
                    onChange={(e) => {
                      const updatedButtons = [...buttons];
                      updatedButtons[index].phone_number = e.target.value;
                      setButtons(updatedButtons);
                    }}
                  />
                </div>
                )}

                {button.type === 'COPY_CODE' && (
                <div className="flex flex-col gap-2">
                  <span className="text-sm medium">Offer Code:</span>
                    
                  <input
                    type="text"
                    value={button.example}
                    className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
                    onChange={(e) => {
                      const updatedButtons = [...buttons];
                      updatedButtons[index].example = e.target.value;
                      setButtons(updatedButtons);
                    }}
                  />
                </div>
                )}
                {button.type === 'URL' && (
                <div className="flex flex-col gap-2">
                  <span className="text-sm medium">URL:</span>
                    
                  <input
                    type="text"
                    value={button.url}
                    className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
                    onChange={(e) => {
                      const updatedButtons = [...buttons];
                      updatedButtons[index].url = e.target.value;
                      setButtons(updatedButtons);
                    }}
                  />
                </div>
                )}
                {/* {(button.type === 'QUICK_REPLY' || button.type === 'MARKETING_OPT_OUT') && (
                <div className="flex flex-col gap-2">
                  <span className="text-sm medium">Custom Input:</span>
                    
                  <input
                    type="text"
                    value={button.custom_input}
                    className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
                    onChange={(e) => {
                      const updatedButtons = [...buttons];
                      updatedButtons[index].custom_input = e.target.value;
                      setButtons(updatedButtons);
                    }}
                  />
                </div>
                )} */}
              </div>
            </li>
          ))}
        </ul>
      </div>
      )}
      
    </div>
  );
}

export default CTButtons;
