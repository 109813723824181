import React from 'react';

function SocialPlanner() {
  return (
    <div>
      Social Planner
    </div>
  );
}

export default SocialPlanner;
