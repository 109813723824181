/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLogin, AiOutlineUser } from 'react-icons/ai';
import { TbMedal } from 'react-icons/tb';
import { fetchAllUsers } from '../../../slice/userSlice';
import Test from './Test';
import { baseURL, companyId } from '../../../slice/HEADERS';
import UserList from './UserList';

const AllUsers = ({ users }) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector((state) => state.users);

  const [userSearch, setUserSearch] = useState('');
  console.log('users',users);
  // useEffect(() => {
  //   dispatch(fetchAllUsers());
  // }, [dispatch]);

  // console.log(data, isLoading, isError);
  return (
    <div className="border shadow-md p-2 rounded-3xl px-10 w-[100%]">
      <div className="flex justify-around h-16 items-center border-b">
        <div className="flex text-sm medium space-x-4 gap-1 items-center text-center w-[23%]">
          <AiOutlineUser />
          Name
        </div> 
        <div className="flex text-sm medium space-x-4 gap-1 justify-center items-center text-center w-[23%]">
          <TbMedal />
          Role       
        </div>
        <div className="flex text-sm medium space-x-4 gap-1 justify-center items-center text-center w-[23%]">
          <AiOutlineLogin />
          {' '}
          Last Login   
        </div>

        <div className="w-[8%] text-center" />
      </div>
      <div className="space-y-7 mt-[40px] pb-10">
        {(users?.length > 0) ? (
          <div>
            {users?.filter((el) => {
              if (userSearch === '') {
                return el;
              }
              return el.name.toLowerCase().includes(userSearch.toLocaleLowerCase());
            })?.map((e) => (
              <UserList data={e} key={e._id} />
              // <SingleUserList 
              //   data={e}
              //   accessLevel={accessLevel}
              //   setUserEdited={setUserEdited}
              // />
            ))}
          </div>
        ) : (
          <div className="text-center italic text-sm text-gray-400">
            No users to show
          </div>
        )}
         
      </div>
    </div>
  ); 
};

export default AllUsers;
