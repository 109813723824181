// userSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from './HEADERS';
import UsersApi from '../Apis/Users';

const initialState = {
  userData: {
    data: null,
    isLoading: false,
    isError: false,
  },
  users: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

export const fetchUserById = createAsyncThunk(
  'data/fetchUserById', // Action type
  async (id, thunkAPI) => {
    const response = await UsersApi.getUserById(id);
    return response.data;
  },
);

// Define an async thunk action creator
export const fetchAllUsers = createAsyncThunk(
  'data/fetchAllUsers', // Action type
  async () => {
    const response = await UsersApi.getAllUsers();
    // console.log('getAllUsers',response);
    return response.data;
  },
);

export const checkUsersByEmail = createAsyncThunk(
  'data/checkUsersByEmail', // Action type
  async (apiUrl, thunkAPI) => {
    const response = await axios.get(apiUrl, config);
    return response.data;
  },
);

export const createUsers = createAsyncThunk(
  'data/createUsers', // Action type
  async (payload, thunkAPI) => {
    const response = await UsersApi.createUser(payload);
    console.log('createUsers',response);
    return response.data;
  },
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.userData.isLoading = true;
        state.userData.isError = false;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.userData.isLoading = false;
        state.userData.isError = false;
        state.userData.data = action.payload;
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.userData.isLoading = false;
        state.userData.isError = true;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.users.isLoading = true;
        state.users.isError = false;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.users.isLoading = false;
        state.users.isError = false;
        state.users.data = action.payload.data;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.users.isLoading = false;
        state.users.isError = true;
      })
      .addCase(createUsers.pending, (state) => {
        state.users.isLoading = true;
        state.users.isError = false;
      })
      .addCase(createUsers.fulfilled, (state, action) => {
        state.users.isLoading = false;
        state.users.isError = false;
        state.users.data = [...state.users.data, action.payload.data];

        // state.users.data = action.payload;
      })
      .addCase(createUsers.rejected, (state) => {
        state.users.isLoading = false;
        state.users.isError = true;
      });
  },
});
  
export default userSlice.reducer;
