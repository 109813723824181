/* eslint-disable no-bitwise */
import React from 'react';
import './WebchatComponent.css';

export function getContrastColor(hexColor) {
  // Convert hex color to RGB
  const rgb = parseInt(hexColor.substring(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  
  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
  // Use white or black based on luminance
  return luminance > 0.5 ? 'black' : 'white';
}

function Bubble({
  imageUrl,greeting,color,iconType,icons, 
}) {
  const textColor = getContrastColor(color);
  return (
    <div className="absolute right-[50px] bottom-[50px] 2xl:bottom-[50px] sm:z-[1] flex flex-col ">
      {/* <div id="elyzian-webchat" className="fixed z-[99999999] font-sans top-[0px] h-fit w-fit sm:w-fit sm:h-fit sm:fixed sm:top-[0px] sm:z-[999999]"> */}
      {/* <div className="absolute z-[1] top-[0px] sm:right-[0px] sm:bottom-[0px] sm:z-[1]"> */}
      {/* <div className="block "> */}
      {/* <div className="fixed right-[50px] bottom-[50px] sm:z-[1] flex flex-col "> */}
      <div style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 10px 20px' }} id="webchat-icon-btn" className="bubble-webchat min-w-[280px]">
        <div className="   flex  w-full">
          {/* <img className="w-[30px] h-[30px]  absolute top-[5px] left-[100px] animate-bounce -mt-[22px] rounded-full" src="https://i.pinimg.com/originals/21/f2/07/21f2078d23f9195570a3711c018328b2.png" alt="Hi" /> */}
          <div
            style={{
              backgroundImage: imageUrl ? `url(${imageUrl})` : `url(${'https://assets.podium.com/images/defaultWebchatPinkAvatar.png'})`,
              backgroundSize: 'cover',
            }}
            className="w-[44px] h-[44px]  rounded-full mr-[13px]"
          />
          <span className="w-[80%] text-[#1A202c] text-sm">{greeting}</span>
        </div>
        <div className="absolute right-5 -top-7"><span className="text-[11px] font-semibold text-[#767676] bg-white shadow-md hover:text-black rounded-full p-1 cursor-pointer">close</span></div>
      </div>
      <div
        id="webchat-icon-btn"
        className="grow text-white fill-white w-[128px] items-center flex pl-1 pr-1 flex-row shadow-gray-400 hover:ring-2 shadow rounded-[90px] cursor-pointer h-[48px] hover:h-[48px] justify-start self-end"
        style={{
          backgroundColor: color,
          color: textColor,
          fill: textColor,
          boxShadow: `${color} 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 10px 20px`,
        }}
      >
        <div className="w-12 h-12 flex items-center justify-center">
          {icons.find((e) => e.icon_id === iconType).icon}
          {' '}
        </div>
        {/* <div className=" flex items-center justify-center mb-1 " style={{ backgroundColor: 'rgb(94, 110, 50)' }}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className=" bg-transparent swirl-icon-forw text-[22px] leading-[40px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(255, 255, 255)' }}><path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z" /></svg></div> */}
        <p className=" medium text-[17px]" style={{ color: textColor }}>Text Us</p>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default Bubble;
