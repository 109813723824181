import React, { useState } from 'react';

function SmallIcon({ icon, onClick ,shadow }) {
  const [focus, setFocus] = useState(false);

  return (
    <button
      onBlur={() => setFocus(false)} 
      onFocus={() => setFocus(true)} 
      onClick={onClick}
      type="button"
      className={`${focus ? 'border border-[#466eff] ring-[3px] transition-all  ring-[#b0c6ff] ' : ''} w-full border h-8 rounded-lg flex items-center  px-2 text-lg ${shadow || 'shadow-md'} shadow-gray-200 gap-2`}
    >
      {icon}
    </button>
  );
}

export default SmallIcon;
