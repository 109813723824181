import React from 'react';
import {
  GrFormAdd,
} from 'react-icons/gr';
import Input from '../../ReusableComponents/Input';

const CTHeaderText = ({
  headerText,setHeaderText,variables,setVariables,
  variableContent,setVariableContent,variableCounter,setVariableCounter,
  Language, 
}) => {
  const handleAddVariable = () => {
    const newHeaderText = `${headerText} {{${variableCounter}}}`;
    setHeaderText(newHeaderText);
    setVariables([...variables, variableCounter]);
    setVariableContent([...variableContent, '']); // Initialize variable content for the new variable
    setVariableCounter(variableCounter + 1);
  };
    
  const handleVariableContentChange = (index, value) => {
    const updatedVariableContent = [...variableContent];
    updatedVariableContent[index] = value;
    setVariableContent(updatedVariableContent);
  };
  
  return (
    <div className="flex flex-col mt-4">
      <Input
        type="text"
        limit
        value={headerText}
        onChange={(e) => setHeaderText(e.target.value)}
        placeholder={`Enter text in ${Language}`}
      />
      {/* <button type="button" onClick={handleAddVariable}>Add Variable</button> */}
      <div className="flex justify-end my-2 gap-3 text-[#1c2b33d3] relative">

        <button disabled={variables?.length > 0} type="button" className="hover:bg-gray-100 disabled:cursor-not-allowed rounded-md hover:shadow-sm p-[3px] px-2 flex items-center text-sm" onClick={handleAddVariable}>
          <GrFormAdd />
          {' '}
          Add Variable
        </button>
      </div>
        
      {variables.map((variable, index) => (
        <div className="border p-3 rounded-lg bg-[#00000008] ring-[#b0c6ff] ring-[1px] space-y-3">
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-600 medium">Variable for Header Content.</span>

            <span className="text-[#1c2b33a6] text-[13px]">Choose a type of variable to include in this header. You can assign only one variable to header.</span>
          </div>

          <div key={variable} className="w-full flex gap-1 items-center">
            <span className="w-[7%] text-[] text-sm">{`{{${variable}}}`}</span>
            <input
              type="text"
              className="outline-none w-[93%]  border focus:border-[#466eff] focus:ring-[3px] transition-all  focus:ring-[#b0c6ff] h-8 p-1.5 rounded-md placeholder:text-[13.5px] text-[13.7px]"
              placeholder={`Enter Content for {{${variable}}}`}
              value={variableContent[index] || ''}
              onChange={(e) => handleVariableContentChange(index, e.target.value)}
            />
          </div>
        </div>

      ))}
    </div>
  ); 
};

export default CTHeaderText;
