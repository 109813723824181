import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appointmentApi from '../Apis/Appointment';

// Define the initial state
const initialState = {
  data: [],
  page: 0,
  totalPages: 1,
  totalAppointments: 1,
  loading: false,
  error: null,
};

// Define an asynchronous thunk function to fetch appointments
export const fetchAppointments = createAsyncThunk(
  'data/fetchAppointments',
  // async (_, { rejectWithValue }) => {
  //   try {
  //     const response = await appointmentApi.getappointments();
  //     return response.data;
  //   } catch (error) {
  //     return rejectWithValue(error.response.data);
  //   }
  // },
  async ({ page }, thunkAPI) => {
    const response = await appointmentApi.getappointments(page);
    return response.data;
  },
);

// Define the appointment slice
const appointmentSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetchAppointments.pending
    builder.addCase(fetchAppointments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    // Handle fetchAppointments.fulfilled
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      const { page } = action.meta.arg;
      console.log('action.payload',action.payload);
      if (page === 1) {
        state.data = action.payload.data.appointments;
        state.page = page;
        state.totalAppointments = action.payload.data.totalAppointments;
        state.totalPages = action.payload.data.totalPages;
      } else {
        // state.data = [...state.data, ...action.payload.data.contacts];
        state.data = [...state.data, ...action.payload.data.appointments];
        state.page = page;
        state.totalAppointments = action.payload.data.totalAppointments;
        state.totalPages = action.payload.data.totalPages;
      }
    });

    // Handle fetchAppointments.rejected
    builder.addCase(fetchAppointments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// Export the appointment slice reducer
export default appointmentSlice.reducer;
