import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { BsChevronDown } from 'react-icons/bs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius: '11px',
      width: 'max-content',
      marginTop: '5px',
      padding: '8px',
      border: '1px solid #E0E6EE',
      boxShadow: ' -1px 3px 18px -7px   rgba(43,112,230,0.5)',
      // minWidth: 150,
      // maxWidth: 1400,
    },
  },
};

export default function SelectOptions({
  label,
  value,
  setValue,
  menuOptions,
  onchange,
  size,
  minWidth,
  disabled,
  maxWidth,
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //   const classes = useStyles();
  return (
    <Box sx={{ minWidth, maxWidth }}>
      <FormControl
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #e0e6ee',

            },
            fontFamily: 'GraphikMedium',
            color: '#292E36',
            fontSize: '14px',
          
            '&:hover fieldset': {
              borderColor: '#466EFF', // Change the hover border color here
            },
          },
          '& .MuiSelect-icon': {
            color: '#292E36',
            fontSize: '12px',
            right: '11px',
          },
        }}
        className={`hover:!ring-[3px]  shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white          
         !rounded-[12px] outline-none
           hover:!ring-[#b0c6ff] hover:!border-[#466eff] medium
        `}
        fullWidth
        size={size}
      >
        <InputLabel
          sx={{ fontFamily: 'GraphikRegular' }}
          className="!text-[13.5px] !text-gray-400"
          id="demo-simple-select-label"
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          MenuProps={MenuProps}
          label={label}
          sx={{ fontFamily: 'GraphikMedium' }}
          onChange={handleChange || onchange}
          IconComponent={BsChevronDown}
          disabled={disabled}
          className="!rounded-[10px]"
        >
          {menuOptions?.map((menu) => (
            <MenuItem
              className="hover:!bg-[#466EFF] !text-[15px] !mt-1 hover:!text-white !rounded-[10px] !text-[#1a202c]"
              key={menu.value}
              value={menu.value}
            >
              {menu.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
